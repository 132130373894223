// JMR Vocabulary page, where flashcard decks are kept

import React, { useState, useEffect, useRef } from "react"
import {
  IonButton,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButtons,
  IonMenuButton,
  IonItem,
  IonList,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonCardTitle,
  IonIcon,
  IonModal,
  IonInput,
  IonTextarea,
  IonToast,
  IonSelect,
  IonSelectOption,

} from "@ionic/react"
import { Storage } from '@ionic/storage';

import wordsService from "../utils/WordsService"
import VideoCardCompactWord from "../components/VideoCard/VideoCardCompactWord"
import {flaskOutline, closeCircleOutline, checkmarkCircleOutline,add,newspaper,heart, fileTrayFull,fileTrayStacked,albums,tv,albumsOutline} from "ionicons/icons"
import Confetti from 'react-confetti'
import { AnimatePresence, motion } from 'framer-motion';
import { languageMapAvail } from "../utils/constants"
import { UserAuth } from '../auth'
import { FlashcardArray } from 'react-quizlet-flashcard';
import Lottie from "lottie-react";
import checkAnimation from "../assets/animations/check.json";
import { toRomaji } from 'wanakana';//romanji to hiragana, no kanji
import {
  apiConvertToHiragana
} from "../utils/apiYT"

type SegmentOption = 'review' | 'active' | 'done'

interface WordsVideo {
  id?: string;
  title?: string;
  channelTitle?: string;
  description?: string;
  thumbnail?: string;
  channelId?: string;
  sourceLanguage?: string;
  sourceText: string;
  sourceTile?: string;
  sourceTileIndex?: number;
  targetLanguage?: string;
  targetText: string;
  targetTile?: string;
  addedDate?: string;
  doneDate?:string;
  reviewCount?: number;
  reviewDates?: string[];
  notes?: string;
}

interface FlashcardData {
  id: number;
  frontHTML: string | JSX.Element;
  backHTML: string | JSX.Element;
  backNotes?: string;
  plainBackText: string;
  sourceText: string,
}

const storage = new Storage();
const storagePromise = storage.create();

const FlashCardPage: React.FC = () => {
  const [activeSegment, setActiveSegment] = useState<SegmentOption>('review')
  const [reviewWords, setReviewWords] = useState<WordsVideo[]>([])
  const [activeWords, setActiveWords] = useState<WordsVideo[]>([])
  const [doneWords, setDoneWords] = useState<WordsVideo[]>([])
//   const [deckWords, setDeckWords] = useState<Set<string>>(new Set())
  const [deckWords, setDeckWords] = useState<string[]>([])
  const [dataVersion, setDataVersion] = useState(0)
  const [showConfetti, setShowConfetti] = useState(false)
  const [previousReviewWordsLength, setPreviousReviewWordsLength] = useState(reviewWords.length)
  const [cardsReviewedToday, setCardsReviewedToday] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  //language codes from context
  const {selectedLanguageCode, nativeLanguageCode} = UserAuth();


  //Flashcards - view mode
  const [showFlashcards, setShowFlashcards] = useState(false);
  const [flashcardData, setFlashcardData] = useState<FlashcardData[]>([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0); // confirm
  const [isFlipped, setIsFlipped] = useState(false);
  const flashcardControlRef = useRef<any>({});
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);

  // **Separate state variables for Quiz**
  const [showQuiz, setShowQuiz] = useState(false); // **New state variable**
  const [quizData, setQuizData] = useState<FlashcardData[]>([]); // **New state variable**
  const [currentQuizCardIndex, setCurrentQuizCardIndex] = useState(0); // **New state variable**
  const quizControlRef = useRef<any>({}); // **New state variable**

  // **State to track user's answers and checked status for each quiz card**
  const [userAnswers, setUserAnswers] = useState<{ [index: number]: string }>({});
  // **Quiz mode state**
  const [isQuizMode, setIsQuizMode] = useState(false);
  const [userAnswer, setUserAnswer] = useState("");
  const [score, setScore] = useState({ correct: 0, total: 0 });
  const [answerChecked, setAnswerChecked] = useState<{ [index: number]: boolean }>({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState<{ [index: number]: boolean }>({});
  // const [isAnswerCorrect, setIsAnswerCorrect] = useState<{ [index: number]: boolean | undefined }>({});

  // **State to track quiz results for each card**
  const [quizResults, setQuizResults] = useState<{ [index: number]: boolean }>(
    {}
  );
   

  const inputRef = useRef<HTMLIonInputElement | null>(null);

  const [showAnimation, setShowAnimation] = useState<boolean>(false);





  //update hiragana for any japanese words that are missing it
  useEffect(() => {
    processVocabulary();
    console.log('[flashcardControlRef.current]: ', flashcardControlRef.current);

  }, []);

  const processVocabulary = async () => {
    try {
      // Step 1: Fetch all words from `srvocab`
      const vocabDeck = await wordsService.getActiveWords(); // Get `srvocab` contents
      const wordsToConvert: string[] = [];
      const wordIndices: number[] = []; // Track indices of words needing updates
      const wordFields: ("sourceText" | "targetText")[] = []; // Track which field to update

      vocabDeck.forEach((word, index) => {
        // Check if sourceLanguage or targetLanguage is 'ja'
        if (word.sourceLanguage === "ja" && (!word.hiragana || word.hiragana.trim() === "")) {
          wordsToConvert.push(word.sourceText); // Add sourceText for conversion
          wordIndices.push(index);
          wordFields.push("sourceText");
        } else if (word.targetLanguage === "ja" && (!word.hiragana || word.hiragana.trim() === "")) {
          wordsToConvert.push(word.targetText); // Add targetText for conversion
          wordIndices.push(index);
          wordFields.push("targetText");
        }
      });

      if (wordsToConvert.length > 0) {
        // Step 2: Call the API to convert words to Hiragana
        const hiraganaList = await apiConvertToHiragana(wordsToConvert);

        // Step 3: Update the vocabulary deck with the returned Hiragana values
        hiraganaList.forEach((hiragana, idx) => {
          const wordIndex = wordIndices[idx];
          const field = wordFields[idx];

          if (wordIndex !== undefined && field) {
            vocabDeck[wordIndex].hiragana = hiragana; // Add Hiragana value
          }
        });

        // Save the updated deck back to storage
        await wordsService.storage.set("srvocab", vocabDeck);
        console.log("Updated srvocab with Hiragana values.");
        // update list again, user will see a flash but can't be helped
        fetchData();
      }
      console.log("Num Processed Hiragana: " + wordsToConvert.length);
    } catch (error) {
      console.error("Error processing vocabulary:", error);
    }
  };


  // useEffect(() => {
  //   // const data = reviewWords.map((word, index) => ({
  //   //   id: index,
  //   //   frontHTML: word.sourceText,
  //   //   backHTML: word.targetText,
  //   //   backNotes: word.notes,
  //   // }));
  //   const data = reviewWords.map((word, index) => ({
  //     id: index,
  //     frontHTML: (
  //       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
  //         {word.sourceText}
  //       </div>
  //     ),
  //     backHTML: (
  //       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
  //         <div>{word.targetText}</div>
  //         {word.notes && (
  //           <div style={{ marginTop: '10px', fontSize: '0.6em', color: 'gray', textAlign: 'center' }}>
  //             {word.notes}
  //           </div>
  //         )}
  //       </div>
  //     ),
  //   }));
  //   setFlashcardData(data);
  //   setDataVersion((prevVersion) => prevVersion + 1);
  // }, [reviewWords]);

  //Flashcards create both view only and quiz
  useEffect(() => {
    const data = reviewWords.map((word, index) => ({
      id: index,
      sourceText: word.sourceText,
      frontHTML: (
        <div
          style={{
            backgroundColor: "transparent !important",display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' 
          }}
        >
          {word.sourceText}
        </div>
      ),
      backHTML: (
        <div
          style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'
          }}
        >
          <div>{word.targetText}</div>
          {word.notes && (
            <div style={{ marginTop: '10px', fontSize: '0.6em', color: 'gray', textAlign: 'center' }}>
              {word.notes}
            </div>
          )}
        </div>
      ),
      plainBackText: word.targetText, // For answer comparison in quiz
    }));
    setFlashcardData(data);
    setQuizData(data);
    setDataVersion((prev) => prev + 1);
  }, [reviewWords]);


  // **Reset quiz state when starting a new quiz**
useEffect(() => {
  if (showQuiz) {
    setCurrentQuizCardIndex(0);
    setUserAnswers({});
    setAnswerChecked({});
    setIsAnswerCorrect({});
    setScore({ correct: 0, total: quizData.length }); // Initialize score

    // Set focus to input field
    
    if (inputRef.current) {
      inputRef.current.getInputElement().then((input) => {
        input.focus();
      });
    }

  }
}, [showQuiz]);



  // useEffect(() => {
  //   if (showFlashcards) {
  //     setCurrentCardIndex(0);
  //     setIsFlipped(false);
  //   }
  // }, [showFlashcards]);
  useEffect(() => {
    if (showFlashcards) {
      setCurrentFlashcardIndex(0);
      // setIsFlipped(false);
      setUserAnswer("");
      setScore({ correct: 0, total: flashcardData.length });
      setIsAnswerCorrect({});
      setAnswerChecked({});
      // **Reset quiz results when starting a new quiz**
      setQuizResults({});
    }
  }, [showFlashcards]);
  

  
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (activeSegment === 'review' && reviewWords.length === 0 && previousReviewWordsLength > 0 && !showConfetti) {
      setShowConfetti(true)
      playCompleteSound()
      // setShowAnimation(true)
      setTimeout(() => {
        setShowConfetti(false)
      }, 5000)
    } else if (reviewWords.length > 0 && showConfetti) {
      setShowConfetti(false)
      // setShowAnimation(true)
    }
    setPreviousReviewWordsLength(reviewWords.length)
  }, [activeSegment, reviewWords, showConfetti])

  //get and save number of reviewed cards
  useEffect(() => {
  const initStorage = async () => {
    await storagePromise;
    await storage.create();
    const today = new Date().toDateString();
    const storedDate = await storage.get('cardsReviewedDate');
    if (storedDate !== today) {
      // New day, reset count
      await storage.set('cardsReviewedToday', 0);
      await storage.set('cardsReviewedDate', today);
      setCardsReviewedToday(0);
    } else {
      // Same day, get current count
      const count = (await storage.get('cardsReviewedToday')) || 0;
      setCardsReviewedToday(count);
    }
  };
  initStorage();
}, []);




  //get word lists from wordService
  const fetchData = async () => {
    // Fetch review words: words that are up for spaced repetition (SR) review
    const review = await wordsService.getReviewWords()
    setReviewWords(review)

    // Fetch active words: all words being reviewed at different stages of spaced repetition (SR)
    const active = await wordsService.getActiveWords()
    setActiveWords(active)

    // Fetch done words: all words that have gone thru SR review and are done
    const done = await wordsService.getDoneWords()
    setDoneWords(done)

    // For cust card add: Set deckWords for consistency with VideoCardCompactWord component
    const deck = await wordsService.getDeckWords()
    // setDeckWords(new Set(deck))
    setDeckWords(deck)
  }

  // Function to update deckWords when a word is added back to active
//   const updateDeckWords = (newWord: string) => {
//     setDeckWords((prevDeckWords) => new Set(prevDeckWords).add(newWord))
//   }
const updateDeckWords = (newWord: string) => {
    setDeckWords((prevDeckWords) => {
      if (!prevDeckWords.includes(newWord)) {
        return [...prevDeckWords, newWord]
      } else {
        return prevDeckWords
      }
    })
  }

  const handleSegmentChange = (value: string | undefined) => {
    if (value === 'review' || value === 'active' || value === 'done') {
      setActiveSegment(value as SegmentOption)
    }
  }
  
//   const handleWordUpdated = async () => {
//     // Refresh the data
//     await fetchData()
//   }
//   const handleWordUpdated = async () => {
//     await fetchData()
//     setDataVersion(prev => prev + 1)
//   }
const handleWordUpdated = async () => {
    await storagePromise;
    // Increment the daily count
    const today = new Date().toDateString();
    const storedDate = await storage.get('cardsReviewedDate');
    if (storedDate !== today) {
      // New day, reset count
      await storage.set('cardsReviewedToday', 1);
      await storage.set('cardsReviewedDate', today);
      setCardsReviewedToday(1);
    } else {
      // Same day, increment count
      let count = (await storage.get('cardsReviewedToday')) || 0;
      count += 1;
      await storage.set('cardsReviewedToday', count);
      setCardsReviewedToday(count);
    }
  
    // Existing code to refresh data
    await fetchData();
    setDataVersion((prev) => prev + 1);
  };
  
  //custom card creation *************************************
  const [newCard, setNewCard] = useState({
    sourceLang: selectedLanguageCode || 'en',
    sourceText: "",
    targetLang: nativeLanguageCode || "en",
    targetText: "",
    notes: "",
  });
  
  const handleAddCard = async () => {
    // Validate input
    if (!newCard.sourceText || !newCard.targetText) {
      setToastMessage("Source and Target texts are required");
      setShowToast(true);
    }
  
    // Create a new card object
    const card = {
      id: new Date().getTime().toString(), // Generate a unique ID
      title: "", // Since there's no video, this can be empty or set to sourceText
      channelTitle: "", // No channel
      description: "",
      thumbnail: "", // No thumbnail
      channelId: "",
      sourceLanguage: newCard.sourceLang || 'en',
      sourceText: newCard.sourceText,
      sourceTile: "",
      sourceTileIndex: -1,
      targetLanguage: newCard.targetLang || 'en',
      targetText: newCard.targetText,
      targetTile: "",
      addedDate: new Date().toISOString(),
      reviewCount: 0,
      reviewDates: [],
      notes: newCard.notes,
      hiragana: ""
    };
  
    //api to generate hiragana value
    // Check if Hiragana conversion is needed
    let hiraganaValue = "";
    const needsHiragana =
      (card.sourceLanguage === "ja" || card.targetLanguage === "ja") &&
      card.hiragana.trim() === "";

    if (needsHiragana) {
      try {
        // Determine the text to convert (sourceText or targetText)
        const textToConvert =
          card.sourceLanguage === "ja" ? card.sourceText : card.targetText;

        // Call the Hiragana API
        const [convertedHiragana] = await apiConvertToHiragana([textToConvert]);

        // Assign the Hiragana value to the card
        hiraganaValue = convertedHiragana;
        card.hiragana = hiraganaValue;
      } catch (error) {
        console.error("Error converting text to Hiragana:", error);
      }
    }



    // Save the new card using wordsService
    const success = await wordsService.addToDeck(card);
  
    if (success) {
      // Refresh the data
      await fetchData();
    
      // Reset the form
      setNewCard({
        sourceLang: selectedLanguageCode || 'en',
        sourceText: "",
        targetLang: nativeLanguageCode || "en",
        targetText: "",
        notes: "",
      });
    
      // Close the modal
      setShowAddModal(false);
    } else {
      // Handle the case where the word already exists
      // Show a toast or alert to inform the user
      setToastMessage("Word already exists in the deck");
      setShowToast(true);
    }
  };
// *****************************************

  function playCompleteSound() {
    const audio = new Audio(require('../assets/sounds/flashcard-review-done2.mp3'));
    audio.volume = 0.3;
    audio.play().catch(error => console.error('Failed to play audio:', error));
  }


  // **Quiz answer submission handler**
  // **Flashcards (Review/Self Practice) Handlers**
  const handleFlashcardNext = () => {
    if (currentFlashcardIndex < flashcardData.length - 1) {
      flashcardControlRef.current.nextCard();
      setCurrentFlashcardIndex(currentFlashcardIndex + 1);
    }
  };

  const handleFlashcardPrev = () => {
    if (currentFlashcardIndex > 0) {
      flashcardControlRef.current.prevCard();
      setCurrentFlashcardIndex(currentFlashcardIndex - 1);
    }
  };

  // **Quiz Handlers**
  const handleQuizAnswerChange = (e: any) => {
    const newUserAnswers = { ...userAnswers };
    newUserAnswers[currentQuizCardIndex] = e.detail.value!;
    setUserAnswers(newUserAnswers);
  };

  const handleQuizSubmit = () => {
    const currentCard = quizData[currentQuizCardIndex];
    const correctAnswer = currentCard.plainBackText.trim().toLowerCase();
    const userAnswer =
      userAnswers[currentQuizCardIndex]?.trim().toLowerCase() || "";

    const isCorrect = userAnswer === correctAnswer;

    setIsAnswerCorrect((prev) => ({
      ...prev,
      [currentQuizCardIndex]: isCorrect,
    }));

    setAnswerChecked((prev) => ({
      ...prev,
      [currentQuizCardIndex]: true,
    }));

    // **Update score**
    setScore((prevScore) => ({
      ...prevScore,
      correct: isCorrect ? prevScore.correct + 1 : prevScore.correct,
    }));

      // **Update quizResults using sourceText as key**
  setQuizResults((prevResults) => ({
    ...prevResults,
    [currentCard.sourceText]: isCorrect,
  }));


  };


  useEffect(() => {
    setTimeout(() => {
    if (inputRef.current) {
      inputRef.current.getInputElement().then((input) => {
        if (!input.disabled) {
          input.focus();
        }
      });
    }
  },0);
  }, [currentQuizCardIndex]);
// }, [currentQuizCardIndex, answerChecked[currentQuizCardIndex]]);

  
  const handleQuizNext = () => {
    if (currentQuizCardIndex < quizData.length - 1) {
      quizControlRef.current.nextCard();
      setCurrentQuizCardIndex(currentQuizCardIndex + 1);

      // // Reset user answer and answer checked state for the next question
      // setUserAnswers((prev) => ({
      //   ...prev,
      //   [currentQuizCardIndex + 1]: '',
      // }));
      // setAnswerChecked((prev) => ({
      //   ...prev,
      //   [currentQuizCardIndex + 1]: false,
      // }));
      // setIsAnswerCorrect((prev) => {
      //   const newState = { ...prev };
      //   delete newState[currentQuizCardIndex + 1];
      //   return newState;
      // });
      

      // Set focus to input field
      if (inputRef.current) {
        inputRef.current.getInputElement().then((input) => {
          input.focus();
        });
      }

    }
  };

  const handleQuizPrev = () => {
    if (currentQuizCardIndex > 0) {
      quizControlRef.current.prevCard();
      setCurrentQuizCardIndex(currentQuizCardIndex - 1);
    }
  };


  const renderWordsList = (words: WordsVideo[], segment: SegmentOption,
                            quizResults?: { [key: string]: boolean }) => {
    return words.map((video, index) => (
        <motion.div
        key={video.sourceText}
        layout
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 100 }}
        transition={{ duration: 0.5 }}
      >
      <VideoCardCompactWord
        video={video}
        key={`${video.sourceText}`}
        deckWords={deckWords}
        updateDeckWords={updateDeckWords}
        onWordUpdated={handleWordUpdated}
        showProgress={segment !== 'done'} // Hide progress in 'done'
        showActions={segment !== 'done'}  // Hide actions in 'done'
        isInDoneSegment={segment === 'done'} // Indicate if in 'done' segment
        // JMR
        quizResult={quizResults ? quizResults[video.sourceText] : undefined}
 
      />
      </motion.div>
    ))
  }

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />

      {/* Form to add new custom card------------------------------- */}
      <IonModal isOpen={showAddModal} onDidDismiss={() => setShowAddModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Add Custom Card</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowAddModal(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {/* Source Language and Text */}
            {/* <IonItem> */}
              <IonGrid>
                <IonRow >
                  {/* Source Language Select */}
                  <IonCol size="3">
                    <IonLabel position="stacked">Language</IonLabel>
                    <IonSelect style={{backgroundColor:"rgb(50,51,69"}}
                      value={newCard.sourceLang}
                      placeholder="Select"
                      onIonChange={(e) =>
                        setNewCard({ ...newCard, sourceLang: e.detail.value! })
                      }
                      selectedText={newCard.sourceLang} // Display code when selected
                    >
                      {Object.entries(languageMapAvail).map(([code, name]) => (
                        <IonSelectOption key={code} value={code}>
                          {name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonCol>
                  {/* Source Text Input */}
                  <IonCol size="9">
                    <IonLabel position="stacked">Source Text</IonLabel>
                    <IonInput style={{backgroundColor:"rgb(50,51,69"}}
                      value={newCard.sourceText}
                      onIonChange={(e) =>
                        setNewCard({ ...newCard, sourceText: e.detail.value! })
                      }
                      required
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            {/* </IonItem> */}

            {/* Target Language and Text */}
            {/* <IonItem> */}
              <IonGrid>
                <IonRow >
                  {/* Target Language Select */}
                  <IonCol size="3">
                    <IonLabel position="stacked">Language</IonLabel>
                    <IonSelect style={{backgroundColor:"rgb(50,51,69"}}
                      value={newCard.targetLang}
                      placeholder="Select"
                      onIonChange={(e) =>
                        setNewCard({ ...newCard, targetLang: e.detail.value! })
                      }
                      selectedText={newCard.targetLang} // Display code when selected
                    >
                      {Object.entries(languageMapAvail).map(([code, name]) => (
                        <IonSelectOption key={code} value={code}>
                          {name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonCol>
                  {/* Target Text Input */}
                  <IonCol size="9">
                    <IonLabel position="stacked">Target Text</IonLabel>
                    <IonInput style={{backgroundColor:"rgb(50,51,69"}}
                      value={newCard.targetText}
                      onIonChange={(e) =>
                        setNewCard({ ...newCard, targetText: e.detail.value! })
                      }
                      required
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            {/* </IonItem> */}

            {/* Notes */}
            <IonItem>
              <IonLabel position="stacked">Notes</IonLabel>
              <IonTextarea style={{backgroundColor:"rgb(50,51,69"}}
                value={newCard.notes}
                onIonChange={(e) =>
                  setNewCard({ ...newCard, notes: e.detail.value! })
                }
              />
            </IonItem>
          </IonList>
          <IonButton expand="block" onClick={handleAddCard}>
            Add Card
          </IonButton>
        </IonContent>
      </IonModal>




      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>
          <IonTitle>Vocabulary</IonTitle>
        </IonToolbar>
        <IonToolbar>
        <IonSegment value={activeSegment} onIonChange={(e) => handleSegmentChange(e.detail.value)}>
            <IonSegmentButton value="review">
              <IonLabel>Review</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="active">
              <IonLabel>Active</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="done">
              <IonLabel>Done</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="compact-list-padding">
        {showConfetti && <Confetti />}
        {/* {activeSegment === 'review' && renderWordsList(reviewWords, 'review')}
        {activeSegment === 'active' && renderWordsList(activeWords, 'active')}
        {activeSegment === 'done' && renderWordsList(doneWords, 'done')} */}
        {activeSegment === 'review' && (
          <>
            {/* Metrics Display for Review Segment */}
            {/* <IonItem>
              <IonLabel>Cards To Review: {reviewWords.length}</IonLabel>
            </IonItem> */}


            <IonRow>
              {/* SEGMENT 1 */}
              {/* First info Card */}
              <IonCol size="12" size-md="6">
                      <IonCard color="warning">
                        <IonGrid>
                          <IonRow>
                            {/* Left Column for IonCardTitle */}
                            <IonCol size="3" className="avatar-column">
                              <IonCardTitle>{reviewWords.length}</IonCardTitle>
                            </IonCol>

                            {/* Right Column for Subtitle and Content */}
                            <IonCol size="9">
                            <IonCardTitle style={{ fontSize: '1.6em' }}>Cards To Review</IonCardTitle>
                            
                              <IonIcon
                                color="medium"
                                    icon={albums}
                                size = "large"
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                  </IonCol>


                  {/* Second info Card */}
                  <IonCol size="12" size-md="6">
                      <IonCard color="warning">
                        <IonGrid>
                          <IonRow>
                            {/* Left Column for IonCardTitle */}
                            <IonCol size="3" className="avatar-column">
                              <IonCardTitle>{cardsReviewedToday}</IonCardTitle>
                            </IonCol>

                            {/* Right Column for Subtitle and Content */}
                            <IonCol size="9">
                            <IonCardTitle style={{ fontSize: '1.6em' }}>Reviewed Today</IonCardTitle>
                              
                              <IonIcon
                                color="medium"
                                    icon={fileTrayFull}
                                size = "large"
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                  </IonCol>
            </IonRow>


            {/* <IonButton fill="outline" expand="block" color="dark" style={{ paddingLeft:"20%", paddingRight:"20%"}} onClick={() => setShowFlashcards(true)}>
              <IonIcon slot="start" icon={albumsOutline}></IonIcon>
              Start Flashcards
            </IonButton> */}
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <IonButton
                    fill="outline"
                    expand="block"
                    color="dark"
                    style={{ paddingLeft: "5%", paddingRight: "5%" }}
                    onClick={() => {
                      setShowFlashcards(true);
                    }}
                    disabled={reviewWords.length === 0}
                  >
                    <IonIcon slot="start" icon={albumsOutline}></IonIcon>
                    Flashcards
                  </IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton
                    fill="outline"
                    expand="block"
                    color="dark"
                    style={{ paddingLeft: "5%", paddingRight: "5%" }}
                    onClick={() => {
                      setShowQuiz(true);
                    }}
                    disabled={reviewWords.length === 0}
                  >
                    <IonIcon slot="start" icon={flaskOutline}></IonIcon>
                    Quiz Me! ({score.correct}/{score.total})
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>



            {/* List of Words */}
            <AnimatePresence>
              {/* {renderWordsList(reviewWords, 'review')} */}
              {/* **Pass quizResults to renderWordsList** */}
              {renderWordsList(reviewWords, "review", quizResults)}
            </AnimatePresence>
                              {/* 
                              {showAnimation && (
                    <div className="animation-overlay-card">
                      <Lottie style={{ width: '66%', height: '66%' }} animationData={checkAnimation} loop={true} />
                    </div>
                  )} */}
          </>
        )}


        {activeSegment === 'active' && (
          <>
            {/* Metrics Display for Active Segment */}
            <IonItem >
              <IonLabel style={{fontSize:'1.1em'}}>Total Cards In Review Pipeline: <strong>{activeWords.length}</strong></IonLabel>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => setShowAddModal(true)}
                  shape="round"
                  fill="solid"
                  size="default"
                  color='warning'
                >
                  <IonIcon icon={add} />
                </IonButton>
              </IonButtons>
            </IonItem>








            {/* List of Words */}
            <AnimatePresence>
            {renderWordsList(activeWords, 'active')}
            </AnimatePresence>
          </>
        )}
        {activeSegment === 'done' && (
          <>
            {/* Metrics Display for Done Segment */}
            <IonItem>
              <IonLabel style={{fontSize:'1.1em'}}>Total Completed: <strong>{doneWords.length}</strong></IonLabel>
            </IonItem>
            {/* List of Words */}
            {renderWordsList(doneWords, 'done')}
          </>
        )}
      </IonContent>

      {/* Flashcard modal */}
      {/* <IonModal isOpen={showFlashcards} onDidDismiss={() => setShowFlashcards(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Flashcards</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowFlashcards(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent color="warning">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <FlashcardArray
              key={dataVersion}
              cards={flashcardData}
              cycle={true}
              frontContentStyle={{
                fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                
                fontSize: '2.5em',
                textAlign: 'center',
                paddingTop:'20px',
              }}
              backContentStyle={{
                fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                paddingTop: '20px',
                fontSize: '2.5em',
                textAlign: 'center',
              }}
            />
          </div>
        </IonContent>
      </IonModal> */}

{/* Flashcard modal: View Only - Practice */}
      <IonModal
        isOpen={showFlashcards}
        onDidDismiss={() => setShowFlashcards(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Flashcards</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowFlashcards(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent color="warning">
          <div
            style={{
              // ... your styles
            }}
          >
            <div
              style={{
                marginTop:'20px',
                width: "100%",
                height: "70%",
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FlashcardArray
                // key={currentFlashcardIndex} // To re-render when index changes
                controls={false}
                cards={flashcardData}
                cycle={false}
                showCount={false}
                frontContentStyle={{
                  fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                  fontSize: '2.5em',
                  textAlign: 'center',
                  
                }}
                backContentStyle={{
                  fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                  paddingTop: '0px',
                  fontSize: '2.5em',
                  textAlign: 'center',
                }}
                forwardRef={flashcardControlRef}
                onCardChange={(id: number, index: number) => {
                  setCurrentFlashcardIndex(index);
                }}

              />
            </div>
            {/* Navigation Buttons */}
            <IonGrid style={{ marginTop: "15px" }}>
              <IonRow>
                <IonCol size="5">
                  <IonButton
                  style={{paddingLeft:'8px'}}
                    expand="block"
                    onClick={handleFlashcardPrev}
                    disabled={currentFlashcardIndex === 0}
                  >
                    Previous
                  </IonButton>
                </IonCol>
                <IonCol size="2">
                  <IonLabel     style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: '100%',
                    paddingTop:'12px'
                  }}>
                    {currentFlashcardIndex + 1} / {flashcardData.length}
                  </IonLabel>
                </IonCol>
                <IonCol size="5">
                  <IonButton
                  style={{paddingRight:'8px'}}
                  expand="block"
                    onClick={handleFlashcardNext}
                    disabled={currentFlashcardIndex === flashcardData.length - 1}
                  >
                    Next
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonModal>




      {/* Flashcard modal: Quiz */}
      <IonModal isOpen={showQuiz} onDidDismiss={() => setShowQuiz(false)} onDidPresent={() => {
    // Delay focusing to ensure input is rendered
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.getInputElement().then((input) => {
          input.focus();
        });
      }
    }, 0);
  }}
  >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Quiz</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowQuiz(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent style={{backgroundColor:'#1E202D'}}>
          <div>

          <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol size="auto">
                  <IonLabel color="warning"
                    style={{
                      fontFamily:"'Press Start 2P', sans-serif",
                      // fontFamily:"'Jersey 10 Charted', sans-serif",
                      fontSize: '1.2em',
                      marginBottom: '10px',
                      textAlign: 'center',
                      textShadow: '1px 1px 0px orangered',
                    }}
                  >
                    Score: {score.correct} / {score.total }
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          
                  {/* Input field, icon, and submit button */}
                  <IonGrid>
        <IonRow
          className="ion-justify-content-center ion-align-items-center"
          style={{ marginBottom: '10px' }}
        >
          {answerChecked[currentQuizCardIndex] &&
            isAnswerCorrect[currentQuizCardIndex] !== undefined && (
              <IonCol size="auto">
                <IonIcon
                  icon={
                    isAnswerCorrect[currentQuizCardIndex]
                      ? checkmarkCircleOutline
                      : closeCircleOutline
                  }
                  color={
                    isAnswerCorrect[currentQuizCardIndex] ? 'success' : 'danger'
                  }
                  style={{ fontSize: '24px' }}
                />
              </IonCol>
            )}

          <IonCol size="auto">
            <IonInput
              ref={inputRef}
              style={{
                color: 'rgb(221,229,239)',
                backgroundColor: 'rgb(57,60,78)',
                width: '200px',
                fontSize:"1.3em",
                borderRadius:"5px",
              }}
              placeholder="Your Answer"
              value={userAnswers[currentQuizCardIndex] || ''}
              onIonChange={handleQuizAnswerChange}
              disabled={answerChecked[currentQuizCardIndex]  === true}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !answerChecked[currentQuizCardIndex]) {
                  handleQuizSubmit();
                }
              }}
            />
          </IonCol>
          <IonCol size="auto">
            <IonButton
              onClick={handleQuizSubmit}
              disabled={answerChecked[currentQuizCardIndex]}
              style={{ marginLeft: '10px' }}
            >
              Submit
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>


<div
  style={{ width: "100%", height: "70%", position: "relative", display: 'flex',justifyContent: 'center' }}
>

  {/* Background Image */}
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      // backgroundImage: "url('/assets/generic.png')", // Replace with your image path
      // backgroundSize: "cover", // Ensures the image covers the entire div
      // backgroundPosition: "center", // Centers the image
      zIndex: 0, // Pushes the image behind the FlashcardArray
    }}
  />


  {/* Overlay to prevent flipping */}
  {!answerChecked[currentQuizCardIndex] && (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
    />
  )}




              <FlashcardArray
                // key={currentQuizCardIndex} // To re-render when index changes
                cards={quizData}
                cycle={false}
                controls={false}
                showCount={false}
                frontContentStyle={{
                  background: "radial-gradient(rgba(86,96,128), rgba(93, 96, 120))",

                  textAlign: 'center',
                  color:"white",
                  fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                  fontSize: '2.5em',
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                }}
                backContentStyle={{
                  backgroundColor:"rgb(86,96,121)",
                  color:"rgb(255,243,142)",
                  fontWeight: 700, fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                  paddingTop: '0px',
                  fontSize: '2.5em',
                  textAlign: 'center',
                }}
                forwardRef={quizControlRef}
                onCardChange={(id: number, index: number) => {
                  setCurrentQuizCardIndex(index);
                }}
              />
            </div>

            {/* Navigation Buttons */}
            <IonGrid >
              <IonRow style={{ marginTop: "15px" }}>
                <IonCol size="5">
                  <IonButton
                    expand="block"
                    onClick={handleQuizPrev}
                    disabled={currentQuizCardIndex === 0}
                  >
                    Previous
                  </IonButton>
                </IonCol>
                <IonCol size="2">
                  <IonLabel style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: '100%',
                    paddingTop:'12px'}}>
                    {currentQuizCardIndex + 1} / {quizData.length}
                  </IonLabel>
                </IonCol>
                <IonCol size="5">
                  <IonButton
                    expand="block"
                    onClick={handleQuizNext}
                    disabled={currentQuizCardIndex === quizData.length - 1}
                  >
                    Next
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>



          </div>
        </IonContent>
      </IonModal>

    </IonPage>
  )
}

export default FlashCardPage
