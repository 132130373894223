import React, { useState, useEffect } from "react";
import {
  IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton,
  IonInput, IonItem, IonList, IonMenuButton, IonButtons, IonBackButton, IonAlert
} from "@ionic/react";
import TodoItem from '../components/Todo/TodoItem';
import "./pages.scss";
import { Storage } from "@ionic/storage";
import Confetti from 'react-confetti';
import Lottie from "lottie-react";
import createAnimation from "../assets/animations/todo-create-cartoon.json";
import checkAnimation from '../assets/animations/todo-check.json';

const storage = new Storage();
storage.create();

interface Task {
    id: number;
    text: string;
    completed: boolean;
  }


const TodoPage: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [text, setText] = useState('');
  const [showDeleteAllConfirm, setShowDeleteAllConfirm] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCheckAnimation, setShowCheckAnimation] = useState(false);

  useEffect(() => {
    loadTasks(); // Load tasks on component mount
  }, []);

  useEffect(() => {
    if (tasks.length > 0 && tasks.every(task => task.completed)) {
      setShowConfetti(true);
      setShowCheckAnimation(true)
      playCompleteSound();
      setTimeout(() => setShowConfetti(false), 5000); // Show confetti for 4 seconds
      setTimeout(() => setShowCheckAnimation(false), 1200); // Show check animation for 2 seconds
    }
  }, [tasks]);

  const loadTasks = async () => {
    const loadedTasks = await storage.get("todos") || [{ id: 1, text: 'Watch a language video.', completed: false }];
    setTasks(loadedTasks);
  };

  const saveTasks = async (newTasks) => {
    await storage.set("todos", newTasks);
  };

  function addTask(text) {
    if (!text) return;
    const newTask = { id: Date.now(), text, completed: false };
    const newTasks = [newTask, ...tasks];
    setTasks(newTasks);
    setText('');
    saveTasks(newTasks);
  }

  function toggleCompleted(id) {
    const newTasks = tasks.map(task => task.id === id ? { ...task, completed: !task.completed } : task);
    setTasks(newTasks);
    saveTasks(newTasks);
  }

  function deleteTask(id) {
    const newTasks = tasks.filter(task => task.id !== id);
    setTasks(newTasks);
    saveTasks(newTasks);
  }

  function deleteAllTasks() {
    setTasks([]);
    saveTasks([]);
  }

  function updateTaskText(id, newText) {
    const newTasks = tasks.map(task => task.id === id ? { ...task, text: newText } : task);
    setTasks(newTasks);
    saveTasks(newTasks);
  }

  function playCompleteSound() {
    const audio = new Audio(require('../assets/sounds/todo-complete2.mp3'));
    audio.volume = 0.13;
    audio.play().catch(error => console.error('Failed to play audio:', error));
  }
  const completedCount = tasks.filter(task => task.completed).length;
  const totalCount = tasks.length;
 


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton default-href="/home/new" />
          </IonButtons>
          <IonTitle>ToDos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={500} gravity={0.33} wind={0.1} />}
      {showCheckAnimation && (
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
            <Lottie animationData={checkAnimation} style={{ width: '100%', height: '100%' }} />
          </div>
        )}
        <div className="todo-banner">
          <h1 style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', color: 'rgb(255, 255, 255)' }}>
            Tasks Done: {completedCount} / {totalCount}
          </h1>
        </div>
        <IonItem className="custom-searchbar-local">
          <IonInput
            value={text}
            placeholder="Add task in target language"
            onIonChange={e => setText(e.detail.value!)}
            onKeyPress={e => { if (e.key === 'Enter') addTask(text) }}
            maxlength={256}
          />
          <IonButton slot="end" onClick={() => addTask(text)}>Add</IonButton>
        </IonItem>

        <IonList className="todo-list">
          {tasks.length > 0 ? tasks.map(task => (
            <TodoItem
              key={task.id}
              task={task}
              deleteTask={deleteTask}
              toggleCompleted={toggleCompleted}
              updateTaskText={updateTaskText}
            />
          )) : (
            <>
              <h3 style={{ color:"yellow" , textAlign: 'center' }}>Add a todo item in your target language!</h3>
              {/* Show Lottie animation when there are no tasks */}
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <Lottie animationData={createAnimation} style={{ width: '100%', height: '100%' }} />
              </div>
            </>
          )}
        </IonList>

        
        {/* Hide Delete All button when there are less than 3 tasks */}
        {tasks.length >= 3 && (
          <IonButton color="danger" onClick={() => setShowDeleteAllConfirm(true)} style={{ margin: '10px' }}>
            Delete All Tasks
          </IonButton>
        )}
          <IonAlert
            isOpen={showDeleteAllConfirm}
            onDidDismiss={() => setShowDeleteAllConfirm(false)}
            header={'Confirm Delete All'}
            message={'Are you sure you want to delete all tasks?'}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  setShowDeleteAllConfirm(false);
                }
              },
              {
                text: 'Yes, Delete All',
                handler: () => {
                  deleteAllTasks();
                }
              }
            ]}
          />
       


      </IonContent>
    </IonPage>
  );
};

export default TodoPage;
