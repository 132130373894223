//JMR
// Read only version of Player Subs to display the subtitle content with no user interaction
// DisplaySubs.tsx
import React, { useState } from "react";
import { IonItem, IonLabel, IonIcon } from "@ionic/react";
import { caretDownCircleOutline } from "ionicons/icons";
import "./PlayerSubs.scss";
import { AnimatePresence, motion } from 'framer-motion';

interface DisplaySubsProps {
  sourceLanguageCode: string;
  targetLanguageCode: string;
  sourceTileText: string;
  targetTileText: string;
}

const DisplaySubs: React.FC<DisplaySubsProps> = ({
  sourceLanguageCode,
  targetLanguageCode,
  sourceTileText,
  targetTileText,
}) => {
  const [isContextVisible, setIsContextVisible] = useState(false);
  const [isContentOrigVisible, setContentOrigVisible] = useState(true);
  const [isContentVisible, setContentVisible] = useState(true);
  const [rotateOrig, setRotateOrig] = useState(false);
  const [rotate, setRotate] = useState(false);

  return (
    
    <>
    {sourceTileText && targetTileText && (
      <>
      {/* Parent Context Section */}
      <div
        style={{
          background: "transparent",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginTop:'5px',
          marginBottom:'0',
        }}
        onClick={() => {
          setIsContextVisible(!isContextVisible);
        }}
      >
        <IonIcon
        
          icon={caretDownCircleOutline}
          style={{
            
            fontSize:'1.6em',
            color:'rgb(152,211,242)',
            marginRight: "0.33rem",
            transition: "transform 1s",
            transform: isContextVisible ? "rotate(0deg)" : "rotate(-90deg)",
          }}
          
        />
        <IonLabel

          style={{ color:'rgb(152,211,242)', fontSize:'1rem'}}

        >
          {"Context:"}
        </IonLabel>
      </div>
      
      <AnimatePresence>
      {/* Conditionally render the two IonItems based on isContextVisible */}
      {isContextVisible && (
        <>
            <motion.div
            key={sourceTileText}
            layout
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5 }}
          >
          {/* Source Text Section */}
          <IonItem
            className="locked-label"
            style={{
              margin:'0',
              "--background": "transparent",
              background: "transparent",
              "--border-style": "none",
            }}
          >
            <IonLabel
              className="custom-label"
              style={{ color: "rgb(248,248,249)" }}
              position="stacked"
            >
              <IonIcon
                icon={caretDownCircleOutline}
                onClick={() => {
                  setContentOrigVisible(!isContentOrigVisible);
                  setRotateOrig(!rotateOrig);
                }}
                style={{
                  cursor: "pointer",
                  marginRight: "0.33rem",
                  transition: "transform 0.5s",
                  transform: rotateOrig ? "rotate(-90deg)" : "rotate(0deg)",
                }}
                size="small"
              />
              {"Subtitle [" + sourceLanguageCode + "]:"}
            </IonLabel>
            <div
              className="custom-div"
              style={{
                display: isContentOrigVisible ? "block" : "none",
                width: "100%",
              }}
            >
              <div className="subcontent-top">
                <span
                  style={{
                    lineHeight: "1.3rem",
                    fontSize: "1.2rem",
                    color: "rgb(248,248,249)",
                  }}
                >
                  {sourceTileText}
                </span>
              </div>
            </div>
          </IonItem>

          {/* Target Text Section */}
          <IonItem
            className="locked-label"
            style={{
              "--background": "transparent",
              background: "transparent",
              "--border-style": "none",
              "--border-margin":0,
            }}
          >
            <IonLabel
              className="custom-label"
              style={{ color: "rgb(230,230, 100)" }}
              position="stacked"
            >
              <IonIcon
                icon={caretDownCircleOutline}
                onClick={() => {
                  setContentVisible(!isContentVisible);
                  setRotate(!rotate);
                }}
                style={{
                  cursor: "pointer",
                  marginRight: "0.33rem",
                  transition: "transform 0.5s",
                  transform: rotate ? "rotate(-90deg)" : "rotate(0deg)",
                }}
                size="small"
              />
              {"Subtitle [" + targetLanguageCode + "]:"}
            </IonLabel>

            <div
              className="custom-div"
              style={{
                display: isContentVisible ? "block" : "none",
                width: "100%",
              }}
            >
              <div
                className="subcontent-top"
                style={{
                  color: "rgb(218,213,91)",
                  borderRadius: "1px",
                  padding: "3px",
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  border: "none",
                  maxHeight: "3.4rem",
                  overflow: "auto",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2rem",
                    lineHeight: "1.3rem",
                  }}
                >
                  {targetTileText}
                </span>
              </div>
            </div>
          </IonItem>
          </motion.div>
        </>
      )}
     
      </AnimatePresence>
      </>
          )}
    </>

  );

};

export default DisplaySubs;
