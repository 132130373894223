import React, { useState, useEffect } from "react"
import {
  IonRouterLink,
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonDatetime,
  IonLabel, IonList, IonItem,IonThumbnail,
  IonChip,

} from "@ionic/react"
import favoriteVideosService from "../utils/FavoriteVideosService"
import historyVideosService from "../utils/HistoryVideosService"
import userActivityService from "../utils/UserActivityService"
import { useHistory } from "react-router-dom"

import { useMediaQuery } from "react-responsive"
import "./DashboardPage.scss"

import { Storage } from '@ionic/storage';
import {bulbOutline, flashOutline, globeOutline,newspaper,heart, refreshOutline,refreshCircle, albumsOutline,albums,tv, layersOutline, fileTrayFullOutline, barbellOutline, alertCircleOutline} from "ionicons/icons"
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import VideoCardGrid from "../components/VideoCard/VideoCardGrid"
import wordsService from "../utils/WordsService"

import Lottie from "lottie-react";
import checkAnimation from "../assets/animations/check.json";
import pinyin from 'pinyin';
import { toRomaji } from 'wanakana';//romanji to hiragana, no kanji
import {
  apiConvertToHiragana
} from "../utils/apiYT"


// import Kuroshiro from "kuroshiro"; //doesnt work polyfill errors
// import KuromojiAnalyzer from "kuroshiro-analyzer-kuromoji";
import { Browser } from '@capacitor/browser'


interface HistoryVideo {
  id: string;
  title: string;
  channelTitle: string;
  description: string;
  thumbnail: string;
  channelId: string;
  userViews: number;
  lengthText: string;
  publishDate: string;
  viewCount: string;
}

interface ChartDataset {
  label: string;
  data: number[];
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
}

interface ChartData {
  labels: string[];
  datasets: ChartDataset[];
}


interface HighlightedDate {
  date: string;
  textColor: string;
  backgroundColor: string;
}


interface FavoriteVideo {
  id: string
  title: string
  channelTitle: string
  description: string
  thumbnail: string
  channelId: string
  lengthText: string;
  publishDate: string;
  viewCount: string;
}

interface Todo {
  id: number;
  text: string;
  completed: boolean;
}

interface WordsVideo {
  id: string
  title: string
  channelTitle: string
  description: string
  thumbnail: string
  channelId: string
  sourceLanguage: string
  sourceText: string
  sourceTile: string
  sourceTileIndex: number
  targetLanguage: string
  targetText: string
  targetTile: string
}

const storage = new Storage();
storage.create();

const DashboardPage: React.FC = () => {
  
  // Chart config options
  // const chartData = {
  //   labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  //   datasets: [
  //     {
  //       label: 'Video Views',
  //       data: [5, 2, 0, 18, 1, 0, 7],
  //       fill: false,
  //       backgroundColor: 'rgb(252,84,87)',
  //       borderColor: 'rgb(58,158,250)',
  //     },
  //   ],
  // };

  const initialQuoteState = {
    content: "Loading quote...",
    author: ""
  };


  const [chartData, setChartData] = useState<ChartData>({
    labels: [], // Empty initially
    datasets: [
      {
        label: 'Last 7 days view count',
        data: [], // Empty initially
        fill: false,
        backgroundColor: 'rgb(252,84,87)',
        borderColor: 'rgb(58,158,250)',
      },
    ],
  });
  
  
  const chartOptions = {
    scales: {
      x: {
        grid: {
          color: 'black' // X-axis grid line color
        },
        ticks: {
          color: 'lightgrey' // X-axis labels color
        }
      },
      y: {
        grid: {
          color: '#042963'
        },
        ticks: {
          color: 'lightgrey' // Y-axis labels color
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'lightgrey' // Legend labels color
        }
      }
    }
  };

  const chartOptionsLT = {
    scales: {
      x: {
        grid: {
          color: 'black',
          display: false // Hide X-axis grid lines
        },
        ticks: {
          color: 'lightgrey'
        }
      },
      y: {
        grid: {
          color: '#042963',
          display: false // Hide Y-axis grid lines
        },
        ticks: {
          color: 'lightgrey'
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'lightgrey'
        }
      }
    },
    maintainAspectRatio: false,
  };

  

  //Determine size of device
  const isTabletOrDesktop = useMediaQuery({ query: "(min-width: 768px)" })
  const [historyVideos, setHistoryVideos] = useState<HistoryVideo[]>([])
  //const [favorites, setFavorites] = useState([]);
  const [favorites, setFavorites] = useState<FavoriteVideo[]>([])
  const [searchTerm, setSearchTerm] = useState("")
  const history = useHistory()

  const [noteCount, setNoteCount] = useState(0);
  const [channelsCount, setChannelsCount] = useState(0);
  const [highlightedDates, setHighlightedDates] = useState<HighlightedDate[]>([]);
  const [quoteOfTheDay, setQuoteOfTheDay] = useState(initialQuoteState);
  const [quoteOfTheDayTarget, setQuoteOfTheDayTarget] = useState(initialQuoteState);
  const [recentWords, setRecentWords] = useState<WordsVideo[]>([]);

  const [jaHiragana, setJaHiragana] = useState<string>('');
  const [wordOfTheDayData, setWordOfTheDayData] = useState<any>(null);
  const [wordOfTheDay, setWordOfTheDay] = useState<any>(null);
  const [translatedWord, setTranslatedWord] = useState<string>('');
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('en');
  const [isInDeck, setIsInDeck] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [romanizedText, setRomanizedText] = useState<string>('');
  const [wordReviewTodayCount, setWordReviewTodayCount] = useState(0);
  const [wordDoneCount, setWordDoneCount] = useState(0);
  const [wordActiveCount, setWordActiveCount] = useState(0);


  const [showAnimation, setShowAnimation] = useState<boolean>(false);




// State declarations for 30-day, 90-day, and 1-year charts
const [chartData30Days, setChartData30Days] = useState<ChartData>({
  labels: new Array(30).fill(''), // 30 labels
  datasets: [{ label: 'Last 30 days', data: [], fill: true, backgroundColor: 'rgb(252,84,87,.2)', borderColor: 'rgb(58,158,250)' }]
});

const [chartData90Days, setChartData90Days] = useState<ChartData>({
  labels: new Array(30).fill(''), // 90 labels
  datasets: [{ label: 'Last 90 days', data: [], fill: true, backgroundColor: 'rgb(252,84,87,.2)', borderColor: 'rgb(58,158,250)' }]
});

const [chartData1Year, setChartData1Year] = useState<ChartData>({
  labels: new Array(12).fill(''), // 365 labels
  datasets: [{ label: '1 year', data: [], fill: true, backgroundColor: 'rgb(252,84,87, .2)', borderColor: 'rgb(58,158,250)' }]
});

const randomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

//Words data
//get word lists from wordService
const fetchData = async () => {
  // Fetch review words: words that are up for spaced repetition (SR) review
  const review = await wordsService.getReviewWords()
  setWordReviewTodayCount(review.length)

  // Fetch active words: all words being reviewed at different stages of spaced repetition (SR)
  const active = await wordsService.getActiveWords()
  setWordActiveCount(active.length)

  // Fetch done words: all words that have gone thru SR review and are done
  const done = await wordsService.getDoneWords()
  setWordDoneCount(done.length)
}


// Fetch selectedLanguageCode when the component mounts
useEffect(() => {
  const fetchSelectedLanguageCode = async () => {
    const langCode = await userActivityService.storage.get('selectedLanguageCode') || 'en';
    setSelectedLanguageCode(langCode);
  };
  fetchSelectedLanguageCode();
  //get words data
  fetchData()
}, []);

// Fetch the word of the day when the component mounts
useEffect(() => {
  const fetchWordOfTheDay = async () => {
    // Fetch the selected language code
    const langCode =
      (await userActivityService.storage.get("selectedLanguageCode")) || "en";
    setSelectedLanguageCode(langCode);

    // Fetch the word of the day
    const wordOfDayData = await wordsService.getWordOfDay(langCode);

    if (wordOfDayData) {
      setWordOfTheDayData(wordOfDayData);
      setWordOfTheDay(wordOfDayData.wordData);
      setTranslatedWord(wordOfDayData.translatedWord || "");
      //romanize for chinese, japanese
      let romanized = '';
      console.log("Romanizing: " + wordOfDayData.translatedWord );
      if (langCode.startsWith('zh')) {
        romanized = pinyin(wordOfDayData.translatedWord, { style: pinyin.STYLE_TONE }).join(' ');
        console.log("zh Romanized: " + romanized );
      } else if (langCode.startsWith('ja')) {
        const hiraganatext = await apiConvertToHiragana([wordOfDayData.translatedWord]);
        // romanized = toHiragana(wordOfDayData.translatedWord);
        console.log("FirstLoad ja Hiroized: " , hiraganatext );
        // setJaHiragana(hiraganatext['text']);
        setJaHiragana(hiraganatext[0]);
        // romanized = toRomaji(hiraganatext['text']);
        romanized = toRomaji(hiraganatext[0]);
      }
      setRomanizedText(romanized);

      // Check if the word is already in the deck
      const inDeck = await wordsService.isWordInDeck(
        wordOfDayData.wordData.word
      );
      setIsInDeck(inDeck);
    }
  };
  fetchWordOfTheDay();
}, []);


  //Load up the line chart data for 7 days, 30 days, 90 days, and yearly
  useEffect(() => {
    const loadChartData = async () => {
      const yearData = await userActivityService.getYearData();
      const today = new Date();
      let last7DaysData: number[] = [];
      let dayLabels: string[] = [];
  
      let data30Days: number[] = [];
      let labels30Days: string[] = [];
      let data90Days: number[] = [];
      let labels90Days: string[] = []; 
      let labels1Year: string[] = [];
      let data1Year: number[] = [];

      //For 7 days
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        const dateString = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }); // Get day of the week
        
        dayLabels.push(dayOfWeek);
        last7DaysData.push(yearData[date.getFullYear()][dateString] || 0);
      }
  
      // Process 30 days
    for (let i = 29; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      labels30Days.push(date.getDate().toString()); // Push day of the month
      const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      data30Days.push(yearData[date.getFullYear()][dateString] || 0);
      //data30Days.push(randomInRange(1, 18));
    }

    // Process 90 days
    for (let i = 29; i >= 0; i--) {
      let sum = 0;
      for (let j = 0; j < 3; j++) {
        const date = new Date(today);
        date.setDate(today.getDate() - (i * 3 + j));
        const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        sum += yearData[date.getFullYear()][dateString] || 0;
      }
          // Generate label for the start of each 3-day period
          const labelDate = new Date(today);
          labelDate.setDate(today.getDate() - (i * 3));
          labels90Days.push(`${labelDate.toLocaleString('default', { month: 'short' })}-${String(labelDate.getDate()).padStart(2, '0')}`);
          data90Days.push(sum); // or use sum for actual data
          // data90Days.push(randomInRange(1, 66)); // or use sum for actual data
        }


        // Process 1 year
        for (let i = 0; i < 12; i++) {
          let sum = 0;
          const month = new Date(today.getFullYear(), today.getMonth() - i, 1);
          labels1Year.unshift(month.toLocaleString('default', { month: 'short' })); // Unshift to add to the beginning
          while (month.getMonth() === new Date(today.getFullYear(), today.getMonth() - i, 1).getMonth()) {
            const dateString = `${month.getFullYear()}-${String(month.getMonth() + 1).padStart(2, '0')}-${String(month.getDate()).padStart(2, '0')}`;
            sum += yearData[month.getFullYear()][dateString] || 0;
            month.setDate(month.getDate() + 1);
          }
          data1Year.unshift(sum);
          // data1Year.unshift(randomInRange(1, 666)); 
        }

        console.log("90 Days Data Length:", data90Days.length);
        console.log("90 Days Labels Length:", labels90Days.length);
        
          updateChartData(dayLabels, last7DaysData);
          setChartData30Days({ ...chartData30Days, labels: labels30Days, datasets: [{ ...chartData30Days.datasets[0], data: data30Days }] });
          setChartData90Days({ ...chartData90Days,labels: labels90Days, datasets: [{ ...chartData90Days.datasets[0], data: data90Days }] });
          setChartData1Year({ ...chartData1Year, labels: labels1Year,datasets: [{ ...chartData1Year.datasets[0], data: data1Year }] });


        };
    
      loadChartData();
  }, []);
  
  //adding vocabulary word to the deck of flashcards
  const handleAddToDeck = async () => {
    if (wordOfTheDay) {
      const wordData = {
        sourceLanguage: 'en',
        sourceText: wordOfTheDay.word,
        targetLanguage: selectedLanguageCode,
        targetText: translatedWord || wordOfTheDay.word,
        notes: '', // We'll construct notes from definitions
        hiragana: jaHiragana
      };
  
      // Construct notes from definitions
      let notesContent = '';
      wordOfTheDay.meanings.slice(0, 2).forEach((meaning) => {
        notesContent += `-${meaning.partOfSpeech}\n`;
        meaning.definitions.slice(0, 2).forEach((def) => {
          notesContent += `-- ${def.definition}\n`;
        });
      });
      wordData.notes = notesContent;
  
      // Add to deck
      const success = await wordsService.addToDeck(wordData);
      if (success) {
        setIsInDeck(true); // Update state to hide the button
        setShowAnimation(true);

        // Hide the animation after 2 seconds
        setTimeout(() => {
          setShowAnimation(false);
        }, 2000);

      } else {
        // what to do if it not work?
      }
      //refresh word metrics
      fetchData();
    }
  };

  


  const updateChartData = (dayLabels: string[], last7DaysData: number[]) => {
    setChartData({
      labels: dayLabels,
      datasets: [
        {
          ...chartData.datasets[0], // Make sure to spread existing properties
          data: last7DaysData,
        },
      ],
    });
  };
  
  //Fetch the quote of the day
    // Fetch the quote of the day when the component mounts
    useEffect(() => {
      userActivityService.getQuoteOfDay()
        .then(quote => {
          setQuoteOfTheDay(quote[0] || {});
          setQuoteOfTheDayTarget(quote[1] || {});
        })
        .catch(error => console.error("Failed to fetch quote of the day:", error));
    }, []);


  //This loads the calendar highlights to indicate usage streak
  useEffect(() => {
    const loadViewsData = async () => {
      const data = await userActivityService.getYearData();
      const today = new Date();
      const formattedToday = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
      let highlights: HighlightedDate[] = [];
  
      for (const year in data) {
        for (const date in data[year]) {
          if (data[year][date] > 0 && date <= formattedToday) {
            highlights.push({
              date,
              textColor: '#09721b',
              backgroundColor: '#c8e5d0',
            });
          }
        }
      }
      console.log("Setting highlightedDates: ", highlights);
      setHighlightedDates(highlights);
    };
  
    loadViewsData();
  }, []);
  

  //get number of notes from local storage
  useEffect(() => {
    const storage = new Storage();
    storage.create();
    let channelsCounter;

    //init video views per day structure
    const currentYear = new Date().getFullYear();
    //const viewsData = userActivityService.getYearData();  //get last two years worth of data
    //userActivityService.backFillDummyData();

    //get total counts of various assets such as notes, channels.  Favorites might be consolidated here in future but done in own useEffect for now
    const countItems = async () => {
      let count = 0;
      await storage.forEach((value, key) => {
        if (key.startsWith('note_')) {
          count++;
        }
      });
      setNoteCount(count);

      // Reading and counting 'channels' items
      const channels = await storage.get('channels');
      if (channels && Array.isArray(channels)) {
        channelsCounter = channels.length;
      }
      setChannelsCount(channelsCounter);
    };

    countItems();
  }, []);


  //Get Favorites and history from local storage
  useEffect(() => {
    (async () => {
      const favs = await favoriteVideosService.getFavorites()
      setFavorites(favs)
      const hist = await historyVideosService.getHistory()
      setHistoryVideos(hist)
    })()
  }, [])

//Get Todo Items on load
const [todos, setTodos] = useState<Todo[]>([]);

useEffect(() => {
  const loadTodos = async () => {
    const storedTodos = await storage.get('todos') || [];
    setTodos(storedTodos);
  };
  loadTodos();
}, []);

//Get recent words
useEffect(() => {
  const loadRecentWords = async () => {
    const storedRecentWords = await wordsService.getWords();
    setRecentWords(storedRecentWords);
  };
  loadRecentWords();
}, []);


  // Calculating completed tasks
  const completedCount = todos.filter(todo => todo.completed).length;
  const totalCount = todos.length;



  //
  useEffect(() => {
    return history.listen(() => {
      // listen for changes in history
      (async () => {
        const favs = await favoriteVideosService.getFavorites()
        setFavorites(favs)
      })()
    })
  }, [history])


  const handleRefreshWordOfTheDay = async () => {
    if (isRefreshing) return; // Prevent multiple clicks

    setIsRefreshing(true); // Start refreshing
    setRomanizedText('');
    setJaHiragana(''); //reset

    try {
      // Fetch the selected language code
      const langCode =
        (await userActivityService.storage.get("selectedLanguageCode")) || "en";
      setSelectedLanguageCode(langCode);

      // Fetch a new word of the day, forcing refresh
      const wordOfDayData = await wordsService.getWordOfDay(langCode, true);

      if (wordOfDayData) {
        setWordOfTheDayData(wordOfDayData);
        setWordOfTheDay(wordOfDayData.wordData);
        setTranslatedWord(wordOfDayData.translatedWord || "");

        let romanized = '';
        console.log("Romanizing: " + wordOfDayData.translatedWord);
        if (langCode.startsWith('zh')) {
          romanized = pinyin(wordOfDayData.translatedWord, { style: pinyin.STYLE_TONE }).join(' ');
          console.log("zh Romanized: " + romanized);
        } else if (langCode.startsWith('ja')) {
          const hiraganatext = await apiConvertToHiragana([wordOfDayData.translatedWord]);
          // romanized = toHiragana(wordOfDayData.translatedWord);
          console.log("Refresh ja Hiroized: " , hiraganatext );
          // setJaHiragana(hiraganatext['text']);
          // romanized = toRomaji(hiraganatext['text']);
          setJaHiragana(hiraganatext[0]);
          romanized = toRomaji(hiraganatext[0]);
        }
        setRomanizedText(romanized);

        // Check if the new word is already in the deck
        const inDeck = await wordsService.isWordInDeck(
          wordOfDayData.wordData.word
        );
        setIsInDeck(inDeck);
      }
    } catch (error) {
      console.error("Error refreshing word of the day:", error);
      // Optionally, show an error message to the user
    } finally {
      setIsRefreshing(false); // Finish refreshing
    }
  };

  
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const filteredFavorites = favorites.filter((video: any) =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const adjustedFavorites = filteredFavorites.map((video) => ({
    id: { videoId: video.id },
    channelId: video.channelId,
    channelTitle: video.channelTitle,
    lengthText : video.lengthText || "",
    publishDate : video.publishDate || "",
    viewCount : video.viewCount || "",
    snippet: {
      title: video.title,
      channelTitle: video.channelTitle,
      description: video.description,
      thumbnails: {
        medium: {
          url: video.thumbnail,
        },
      },
    },
  }))

  const adjustedHistory = historyVideos.slice(0, 2).map((video) => ({
    id: { videoId: video.id },
    channelId: video.channelId,
    channelTitle: video.channelTitle,
    lengthText : video.lengthText || "",
    publishDate : video.publishDate || "",
    viewCount : video.viewCount || "",
    snippet: {
      title: video.title,
      channelTitle: video.channelTitle,
      description: video.description,
      thumbnails: {
        medium: {
          url: video.thumbnail,
        },
      },
    },
    userViews: video.userViews
  }));
  
  // Function to find the most viewed video
  const getMostViewedVideo = () => {
    return historyVideos.reduce((max, video) => {
      const maxViews = max.userViews || 0;
      const videoViews = video.userViews || 0;
      return maxViews > videoViews ? max : video;
    }, historyVideos[0] || {});
  };

  const mostViewedVideo = getMostViewedVideo();

  //Open Google Translate
  const openGoogleTranslate = async (text) => {
    const encodedText = encodeURIComponent(text);

    //determine which tiles were clicked on, the orig lang or target language. 
    //If value of useTileSubsOrig is false then we need to switch the source and target for trnslation
    let sourceLang, targetLang;
    
    // Default to auto-detect if source language is not specified
    sourceLang = 'en';
    // Default to English if target language is not specified
    targetLang = 'auto';

    const url = `https://translate.google.com/?sl=${targetLang}&tl=${sourceLang}&text=${encodedText}&op=translate`;
    // console.log("GoogleTranslate target lang: ", url);
    await Browser.open({ url });
  };


  return (
    <div className="dash-container">
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
              <IonMenuButton color="primary" />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="compact-list-padding">

        {/*<Videos videos={filteredFavorites} /> */}
        {/* <Videos videos={adjustedFavorites} /> */}
        <IonGrid>
        <IonRow>
          {/* SEGMENT 1 */}
          {/* First Card */}
              <IonCol size="12" size-md="4">
                <IonRouterLink routerLink={`/vocab`}>
                  <IonCard color="warning">
                    <IonGrid>
                      <IonRow>
                        {/* Left Column for IonCardTitle */}
                        <IonCol size="3" className="avatar-column">
                          <IonCardTitle>{wordReviewTodayCount}</IonCardTitle>
                        </IonCol>

                        {/* Right Column for Subtitle and Content */}
                        <IonCol size="9">
                        <IonCardTitle style={{ fontSize: '1.6em' }}>To Review</IonCardTitle>
                        
                          <IonIcon
                            color="danger"
                                icon={flashOutline}
                            size = "large"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonRouterLink>
              </IonCol>


              {/* Second Card */}
              <IonCol size="12" size-md="4">
                <IonRouterLink routerLink={`/vocab`}>
                  <IonCard color="warning">
                    <IonGrid>
                      <IonRow>
                        {/* Left Column for IonCardTitle */}
                        <IonCol size="3" className="avatar-column">
                          <IonCardTitle>{wordActiveCount}</IonCardTitle>
                        </IonCol>

                        {/* Right Column for Subtitle and Content */}
                        <IonCol size="9">
                        <IonCardTitle style={{ fontSize: '1.6em' }}>Words in Deck</IonCardTitle>
                          
                          <IonIcon
                            color="primary"
                                icon={layersOutline}
                            size = "large"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonRouterLink>
              </IonCol>

               {/* Third Card */}
               <IonCol size="12" size-md="4">
                <IonCard color="warning">
                  <IonGrid>
                    <IonRow>
                      {/* Left Column for IonCardTitle */}
                      <IonCol size="3" className="avatar-column">
                        <IonCardTitle>{wordDoneCount}</IonCardTitle>
                      </IonCol>

                      {/* Right Column for Subtitle and Content */}
                      <IonCol size="9">
                      <IonCardTitle style={{ fontSize: '1.6em' }}>Completed Words</IonCardTitle>
                        
                        <IonIcon
                          color="tertiary"
                              icon={fileTrayFullOutline}
                          size = "large"
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </IonCol>
        </IonRow>

        {/*  SEGMENT 2   */}
        <IonRow>

        <IonCol
              style={{ padding: 0 }}
              size="12"
              size-md="12"
              size-lg="4"
            >
              {/* Vocabulary word of the day cards  */}
              <IonCard style={{
                  background: '#2a9d8f', // Example gradient #ed8761-- #1b262a, #bd7960, -- #353A5F, #424530 --#0F0C29, #1E5E98(strong)
                  color: 'white', // Text color for better readability
                  borderRadius: '15px', // Optional: rounded corners
                  padding: '5px', // Optional: some padding inside the card
                  maxHeight: "360px", // Limit the card's height
                  overflowY: "auto",  // Enable vertical scrolling
                }}>
                   <IonCardHeader>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                        <IonCardTitle style={{ fontSize: "1.1em" }}>
                          Vocabulary word of the day:
                        </IonCardTitle>
                        <IonButton fill="clear" disabled={isRefreshing} onClick={handleRefreshWordOfTheDay}>
                          <IonIcon color="warning" icon={refreshOutline} className={isRefreshing ? 'rotating' : ''}/>
                        </IonButton>
                      </div>
                      {wordOfTheDay ? (
                        <>
                          <IonCardTitle style={{ fontSize: "1.68em", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>
                            {wordOfTheDay.word}
                          </IonCardTitle>
                          {translatedWord && selectedLanguageCode !== 'en' && (
                            <>
                              <IonCardTitle>
                                [{selectedLanguageCode}]: {translatedWord}
                              </IonCardTitle>
                              {jaHiragana && (
                                <IonCardSubtitle style={{ fontSize: "1.3em",color:"lightgrey"}}>
                                  {jaHiragana}
                                </IonCardSubtitle>
                              )}
                              {romanizedText && (
                                <IonCardSubtitle style={{ fontSize: "1.2em",color:"lightgrey"}}>
                                  {romanizedText}
                                </IonCardSubtitle>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <IonCardTitle>Loading...</IonCardTitle>
                      )}
                    </IonCardHeader>

                  <IonCardContent>
                    {wordOfTheDay &&
                      wordOfTheDay.meanings.slice(0, 2).map((meaning, index) => (
                        <div key={index}>
                          <p style={{ fontWeight: "bold" , marginBottom: "0px"}}>{meaning.partOfSpeech}</p>
                          <ul style={{ marginTop: "0px" }}>
                            {meaning.definitions.slice(0, 2).map((def, idx) => (
                              <li key={idx}>{def.definition}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                  </IonCardContent>

                  {/* Add To Deck Button */}
                  {!isInDeck && wordOfTheDay && (
                    <IonButton color="success" expand="block" onClick={handleAddToDeck}>
                      Add To Deck
                    </IonButton>
                  )}
                  {/* Animation Overlay */}
                  {showAnimation && (
                    <div className="animation-overlay-card">
                      <Lottie style={{ width: '100%', height: '100%' }} animationData={checkAnimation} loop={false} />
                    </div>
                  )}
              </IonCard>
              
              {/* <IonCard style={{
                  background: '#264653',
                  textShadow: '1px 1px 5px #000000',
                  color: 'white', // Text color for better readability
                  borderRadius: '15px', // Optional: rounded corners
                  padding: '15px' // Optional: some padding inside the card
                }}>
                <IonCardHeader>
                  <IonCardTitle style={{ fontSize: '1.5em' }}><span style={{ fontSize: '1.6rem' }}>"</span>{"Coming Soon!"}<span style={{ fontSize: '1.6rem' }}>"</span></IonCardTitle>
                  <IonCardSubtitle>-{"subtitle"}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent></IonCardContent>
              </IonCard> */}

            </IonCol>


            {/* row 1, column 2 */}
            <IonCol
                style={{ padding: 0 }}
                size="12"
                size-md="6"
                size-lg="4"
              >

                <IonCard>
                  <IonCardHeader>
                    <IonRouterLink routerLink="/todopage">
                      <IonCardTitle style={{ cursor: 'pointer' }}>Daily Todo List 
                                <IonChip color="primary" style={{ color:'blue', marginLeft: '8px' }}>
                                  <IonLabel>{completedCount}</IonLabel>
                                </IonChip>/
                                <IonChip color="warning" style={{ color:'yellow',opacity: 1 }}>
                                  <IonLabel>{totalCount}</IonLabel>
                                </IonChip>
                      </IonCardTitle>
                      <IonCardSubtitle>Write in target language</IonCardSubtitle>
                    </IonRouterLink>
                    <IonCardSubtitle></IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList style={{ maxHeight: '230px', overflowY: 'auto' }}>
                      {todos.map((todo) => (
                        <IonItem key={todo.id} style={{ '--background': 'rgb(110,120,160)', '--ion-item-background': 'rgb(110,120,160)', '--ion-background-color': 'rgb(110,120,160)' }}>
                          <IonLabel style={todo.completed ? { textDecoration: 'line-through' } : {}}>
                            {todo.text}
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonCardContent>
                </IonCard>

                {/*<VideoCardCompact video={video} key={video.id.videoId} /> */}
            </IonCol>


            <IonCol size="12" size-md="6" size-lg="4" className="datetime-center">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IonLabel >Your learning streak</IonLabel>
              <IonDatetime
                showDefaultTitle={false}
                firstDayOfWeek={1}
                color="datedark"
                presentation="date"
                /* value={new Date().toISOString()} */
                value={new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + String(new Date().getDate()).padStart(2, '0')}
                highlightedDates={highlightedDates}

              ></IonDatetime>
            </div>
          </IonCol>




        </IonRow>


        {/*  SEGMENT 3  Quote of day, todo list, Calendar for current date defaulted  */}
        <IonRow>


        <IonCol
              style={{ padding: 0 }}
              size="12"
              size-md="12"
              size-lg="4"
            >
              {/* Quote of the day cards  */}
              <IonCard style={{
                fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif",
                  background: 'linear-gradient( #272b47, #1E5E98)', // Example gradient #ed8761-- #1b262a, #bd7960, -- #353A5F, #424530 --#0F0C29, #1E5E98(strong)
                  color: 'white', // Text color for better readability
                  borderRadius: '15px', // Optional: rounded corners
                  padding: '15px' // Optional: some padding inside the card
                }}>
                Quote of the day:
                <IonCardHeader>
                  <IonCardTitle style={{ fontSize: '1.5em' }}><span style={{ fontSize: '1.6rem' }}>"</span>{quoteOfTheDay.content}<span style={{ fontSize: '1.6rem' }}>"</span></IonCardTitle>
                  <IonCardSubtitle>-{quoteOfTheDay.author}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent></IonCardContent>
              </IonCard>
              
              <IonCard style={{
                  //background: 'linear-gradient( #57370D, #b89206)', // Example gradient #ed8761-- #1b262a, #bd7960, -- #353A5F, #424530 --#0F0C29, #1E5E98(strong)
                  background: 'linear-gradient( #836419,#DBCA34)',
                  textShadow: '1px 1px 5px #000000',
                  color: 'white', // Text color for better readability
                  borderRadius: '15px', // Optional: rounded corners
                  padding: '15px', // Optional: some padding inside the card
                  fontFamily: "Nanum Gothic Coding, Noto Sans,sans-serif"
                }}>
                <IonCardHeader>
                  <IonCardTitle style={{ fontSize: '1.8em' }}><span style={{ fontSize: '1.6rem' }}>"</span>{quoteOfTheDayTarget.content}<span style={{ fontSize: '1.6rem' }}>"</span></IonCardTitle>
                  <IonCardSubtitle>-{quoteOfTheDayTarget.author}</IonCardSubtitle>
  
                </IonCardHeader>
                <IonCardContent></IonCardContent>
                    <IonIcon
                      icon={globeOutline}
                      onClick={() => openGoogleTranslate(quoteOfTheDayTarget.content)}
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        fontSize: '1rem',
                        color: "rgb(100,100,100)",
                        cursor: 'pointer',
                      }}
                    />
              </IonCard>

            </IonCol>




          <IonCol
            style={{ padding: 0 }}
            size="12"
            size-md="6"
            size-lg="4"
          >

            <IonCard>
              <IonCardHeader>
                <IonRouterLink routerLink="/wordspage">
                  <IonCardTitle>Recent Words</IonCardTitle>
                  <IonCardSubtitle>Words that you translated</IonCardSubtitle>
                </IonRouterLink>
              </IonCardHeader>

              <IonCardContent>
                <IonList style={{ maxHeight: '236px', overflowY: 'auto' }}>
                    {recentWords.map((word) => (
                      <IonItem style={{ '--background': 'rgb(112,113,143)', '--ion-item-background': 'rgb(112,113,143)', '--ion-background-color': 'rgb(112,113,143)' }}>
                        <IonGrid>
                          <IonRow>
                            <IonCol size="6">
                              <IonLabel>
                                <strong>{word.sourceLanguage}:</strong> {word.sourceText}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="6">
                              <IonLabel>
                                <strong>{word.targetLanguage}:</strong> {word.targetText}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    ))}
                  </IonList>
              </IonCardContent>
            </IonCard>

            {/*<VideoCardCompact video={video} key={video.id.videoId} /> */}
          </IonCol>

          {/* row 2, column 3 */}
          <IonCol size="12" size-md="6" size-lg="4" className="datetime-center">
            <IonCard>
              {/*<img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/card-media.png" /> */}
              <Line options={chartOptions} style={{borderTopRightRadius: '5px',borderTopLeftRadius: '5px', backgroundColor:"#333867"}} data={chartData} />

              <IonCardHeader>
                <IonCardTitle>Activity</IonCardTitle>
                <IonCardSubtitle>Number of videos viewed for the last 7 days</IonCardSubtitle>
              </IonCardHeader>

              {/* <IonCardContent>Here's a small text description for the card content. Nothing more, nothing less.</IonCardContent> */}
            </IonCard>

          </IonCol>

        </IonRow>

        {/* SEGMENT 4 : last 3 videos watched */}
        <IonRow>Lastest viewed videos:</IonRow>
          <IonRow>
            {adjustedHistory.map((video) => (
              <IonCol
                style={{ padding: 0 }}
                size="12"
                size-md="6"
                size-lg="4"
              >

              <VideoCardGrid video={video} key={video.id.videoId} />
              </IonCol>
            ))}

<IonCol
              style={{ padding: 0 }}
              size="12"
              size-md="6"
              size-lg="4"
            >

            <IonCard>
              <IonRouterLink routerLink={`/video/${mostViewedVideo.id}`}>
                <IonCardHeader>
                
                  <IonCardTitle>
                  <IonIcon style={{ position: 'relative', top: '4px' }} color="light" icon={tv} size="medium" />
                  &nbsp; {mostViewedVideo.userViews}
                   
                  </IonCardTitle>
                  <IonCardSubtitle>Your most viewed video</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                <IonList>
                  <div className="dashboard-page">
                  <IonItem  lines="none" >
                      <IonThumbnail slot="start" >
                        <img alt={mostViewedVideo.title} src={mostViewedVideo.thumbnail} />
                      </IonThumbnail>
                      <IonLabel>{mostViewedVideo.title}</IonLabel>
                  </IonItem>
                  </div>
                </IonList>

                </IonCardContent>
              </IonRouterLink>
            </IonCard>

            {/*<VideoCardCompact video={video} key={video.id.videoId} /> */}
          </IonCol>


          </IonRow>


          {/* Segment 5: additional trend charts */}
          <IonRow>
            {/* 30 Days Chart */}
            <IonCol size="12" size-md="6" size-lg="4">
              <IonCard>
                <Line options={chartOptionsLT} data={chartData30Days} style={{ borderTopRightRadius: '5px', borderTopLeftRadius: '5px', backgroundColor: "#333867" }}/>
              </IonCard>
            </IonCol>

            {/* 90 Days Chart */}
            <IonCol size="12" size-md="6" size-lg="4">
              <IonCard>
                <Line options={chartOptionsLT} data={chartData90Days} style={{ borderTopRightRadius: '5px', borderTopLeftRadius: '5px', backgroundColor: "#333867" }}/>
              </IonCard>
            </IonCol>

            {/* 1 Year Chart */}
            <IonCol size="12" size-md="6" size-lg="4">
              <IonCard>
                <Line options={chartOptionsLT} data={chartData1Year} style={{ borderTopRightRadius: '5px', borderTopLeftRadius: '5px', backgroundColor: "#333867" }}/>
              </IonCard>
            </IonCol>
          </IonRow>


{/* Last Row */}
<IonRow>
          {/* SEGMENT 1 */}
          {/* First Card */}
              <IonCol size="12" size-md="4">
                <IonRouterLink routerLink={`/mynotes`}>
                  <IonCard color="warning">
                    <IonGrid>
                      <IonRow>
                        {/* Left Column for IonCardTitle */}
                        <IonCol size="3" className="avatar-column">
                          <IonCardTitle>{noteCount}</IonCardTitle>
                        </IonCol>

                        {/* Right Column for Subtitle and Content */}
                        <IonCol size="9">
                        <IonCardTitle style={{ fontSize: '1.6em' }}>My Notes</IonCardTitle>
                        
                          <IonIcon
                            color="tertiary"
                                icon={newspaper}
                            size = "large"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonRouterLink>
              </IonCol>


              {/* Second Card */}
              <IonCol size="12" size-md="4">
                <IonRouterLink routerLink={`/favorites`}>
                  <IonCard color="warning">
                    <IonGrid>
                      <IonRow>
                        {/* Left Column for IonCardTitle */}
                        <IonCol size="3" className="avatar-column">
                          <IonCardTitle>{favorites.length}</IonCardTitle>
                        </IonCol>

                        {/* Right Column for Subtitle and Content */}
                        <IonCol size="9">
                        <IonCardTitle style={{ fontSize: '1.6em' }}>Favorites</IonCardTitle>
                          
                          <IonIcon
                            color="danger"
                                icon={heart}
                            size = "large"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonRouterLink>
              </IonCol>

               {/* Third Card */}
               <IonCol size="12" size-md="4">
                <IonCard color="warning">
                  <IonGrid>
                    <IonRow>
                      {/* Left Column for IonCardTitle */}
                      <IonCol size="3" className="avatar-column">
                        <IonCardTitle>{channelsCount}</IonCardTitle>
                      </IonCol>

                      {/* Right Column for Subtitle and Content */}
                      <IonCol size="9">
                      <IonCardTitle style={{ fontSize: '1.6em' }}>Saved Channels</IonCardTitle>
                        
                        <IonIcon
                          color="tertiary"
                              icon={albums}
                          size = "large"
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              </IonCol>
        </IonRow>

        </IonGrid>
      </IonContent>
    </IonPage>
    </div>
  )
}

export default DashboardPage


            /*
            highlightedDates={[
              {
                date: '2023-01-05',
                textColor: '#800080',
                backgroundColor: '#ffc0cb',
              },
              {
                date: '2023-01-06',
                textColor: '#800080',
                backgroundColor: '#ffc0cb',
              },
              {
                date: '2023-01-10',
                textColor: '#09721b',
                backgroundColor: '#c8e5d0',
              },
              {
                date: '2023-01-11',
                textColor: '#09721b',
                backgroundColor: '#c8e5d0',
              },
              {
                date: '2023-01-12',
                textColor: '#09721b',
                backgroundColor: '#c8e5d0',
              },
              {
                date: '2023-01-13',
                textColor: '#09721b',
                backgroundColor: '#c8e5d0',
              },
              {
                date: '2023-01-20',
                textColor: 'var(--ion-color-secondary-contrast)',
                backgroundColor: 'var(--ion-color-secondary)',
              },
              {
                date: '2023-02-13',
                textColor: 'rgb(68, 10, 184)',
                backgroundColor: 'rgb(211, 200, 229)',
              },
            ]}
            */