import { Storage } from "@ionic/storage"

export class UserActivityService {
  storage

  //quotes
  quotes = [
    {
      quote: "Imagination is more important than knowledge. For knowledge is limited, whereas imagination embraces the entire world.",
      author: "Albert Einstein",
    },
    {
      quote: "Life is like riding a bicycle. To keep your balance, you must keep moving.",
      author: "Albert Einstein",
    },
    {
      quote: "Insanity: doing the same thing over and over again and expecting different results.",
      author: "Albert Einstein",
    },
    {
      quote: "If you can't explain it to a six-year-old, you don't understand it yourself.",
      author: "Albert Einstein",
    },
    {
      quote: "Try not to become a man of success, but rather try to become a man of value.",
      author: "Albert Einstein",
    },
    {
      quote: "The important thing is not to stop questioning. Curiosity has its own reason for existence.",
      author: "Albert Einstein",
    },
    {
      quote: "In the middle of difficulty lies opportunity.",
      author: "Albert Einstein",
    },
    {
      quote: "I have no special talent. I am only passionately curious.",
      author: "Albert Einstein",
    },
    {
      quote: "The only source of knowledge is experience.",
      author: "Albert Einstein",
    },
    {
      quote: "Logic will get you from A to B. Imagination will take you everywhere.",
      author: "Albert Einstein",
    },
    {
      quote: "Anyone who has never made a mistake has never tried anything new.",
      author: "Albert Einstein",
    },
    {
      quote: "The true sign of intelligence is not knowledge but imagination.",
      author: "Albert Einstein",
    },
    {
      quote: "We cannot solve our problems with the same thinking we used when we created them.",
      author: "Albert Einstein",
    },
    {
      quote: "A person who never made a mistake never tried anything new.",
      author: "Albert Einstein",
    },
    {
      quote: "Reality is merely an illusion, albeit a very persistent one.",
      author: "Albert Einstein",
    },
    {
      quote: "A clever person solves a problem. A wise person avoids it.",
      author: "Albert Einstein",
    },
    {
      quote: "Gravitation is not responsible for people falling in love.",
      author: "Albert Einstein",
    },
    {
      quote: "Once you stop learning, you start dying.",
      author: "Albert Einstein",
    },
    {
      quote: "Great spirits have always encountered violent opposition from mediocre minds.",
      author: "Albert Einstein",
    },
    {
      quote: "I live in that solitude which is painful in youth, but delicious in the years of maturity.",
      author: "Albert Einstein",
    },
    {
      quote: "Whatever you are, be a good one.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Do I not destroy my enemies when I make them my friends?",
      author: "Abraham Lincoln",
    },
    {
      quote: "I am a slow walker, but I never walk back.",
      author: "Abraham Lincoln",
    },
    {
      quote: "In the end, it's not the years in your life that count. It's the life in your years.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Those who deny freedom to others deserve it not for themselves.",
      author: "Abraham Lincoln",
    },
    {
      quote: "My concern is not whether God is on our side; my greatest concern is to be on God's side, for God is always right.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Nearly all men can stand adversity, but if you want to test a man's character, give him power.",
      author: "Abraham Lincoln",
    },
    {
      quote: "I will prepare and someday my chance will come.",
      author: "Abraham Lincoln",
    },
    {
      quote: "The best way to predict your future is to create it.",
      author: "Abraham Lincoln",
    },
    {
      quote: "No man has a good enough memory to be a successful liar.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Give me six hours to chop down a tree and I will spend the first four sharpening the axe.",
      author: "Abraham Lincoln",
    },
    {
      quote: "You can fool all the people some of the time, and some of the people all the time, but you cannot fool all the people all the time.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Most folks are about as happy as they make up their minds to be.",
      author: "Abraham Lincoln",
    },
    {
      quote: "The ballot is stronger than the bullet.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Always bear in mind that your own resolution to succeed is more important than any one thing.",
      author: "Abraham Lincoln",
    },
    {
      quote: "I do not think much of a man who is not wiser today than he was yesterday.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Better to remain silent and be thought a fool than to speak out and remove all doubt.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Be sure you put your feet in the right place, then stand firm.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Character is like a tree and reputation its shadow. The shadow is what we think it is and the tree is the real thing.",
      author: "Abraham Lincoln",
    },
    {
      quote: "I laugh because I must not cry, that is all, that is all.",
      author: "Abraham Lincoln",
    },
    {
      quote: "Those willing to die will live, and those willing to live will die.",
      author: "Admiral Yi Sun-Shin",
    },
  ];


  constructor() {
    this.storage = new Storage()
    this.storage.create()
  }

  //back fill dummy data, 14 days
  async backFillDummyData() {
    const dummyValues = [3, 0, 13, 6]; // Array of dummy values
    const today = new Date();
    const year = today.getFullYear();
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey) || {};
  
    // Backfill the data for the last 4 days
    for (let i = 0; i < dummyValues.length; i++) {
      const dateToModify = new Date();
      dateToModify.setDate(today.getDate() - i); // Adjust the date backwards
      const dateString = `${dateToModify.getFullYear()}-${String(dateToModify.getMonth() + 1).padStart(2, '0')}-${String(dateToModify.getDate()).padStart(2, '0')}`;
  
      yearData[dateString] = dummyValues[i];
    }
  
    await this.storage.set(yearKey, yearData);
  }
  //---------------------------------------------

//API VERSION of quotes
// async getQuoteOfDay() {
//   const quoteKey = "quote_of_day";
//   const quoteTranslatedKey = "quote_of_day_selected";
//   const selectedLanguageKey = "selectedLanguageCode";
//   const storedQuote = await this.storage.get(quoteKey);
//   const storedQuoteTrans = await this.storage.get(quoteTranslatedKey);
//   const selectedLanguageCode = await this.storage.get(selectedLanguageKey);
//   const today = new Date();
//   const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

//   // Check if stored quote is from today
//   if (storedQuote && storedQuote.date === todayString) {
//     // Check if language has changed and translation is required
//     if (selectedLanguageCode !== "en" && (!storedQuoteTrans || storedQuoteTrans.lang !== selectedLanguageCode)) {
//       const translatedQuote = await this.translateQuote(storedQuote.quote.content, "en", selectedLanguageCode);
//       const translatedQuoteData = { content: translatedQuote, author: storedQuote.quote.author };
//       // Store translated quote
//       await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
//       return [storedQuote.quote, translatedQuoteData];
//     } else {
//       // No translation needed, return stored quote
//       return [storedQuote.quote, storedQuoteTrans ? storedQuoteTrans.quote : null];
//     }
//   } else {
//     // Fetch and store new quote
//     try {
//       console.log("API call to get QUOTES!");
//       const response = await fetch('https://api.quotable.io/random');
//       if (!response.ok) throw new Error('Failed to fetch quote');
//       const quoteData = await response.json();
      
//       // Store new quote with today's date
//       await this.storage.set(quoteKey, { quote: quoteData, date: todayString });

//       if (selectedLanguageCode && selectedLanguageCode !== "en") {
//         const translatedQuote = await this.translateQuote(quoteData.content, "en", selectedLanguageCode);
//         const translatedQuoteData = { content: translatedQuote, author: quoteData.author };
//         await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
//         return [quoteData, translatedQuoteData];
//       } else {
//         return [quoteData, null];
//       }
//     } catch (error) {
//       console.error('Error fetching quote of the day:', error);
//       throw error;
//     }
//   }
// }

//local variable verion
async getQuoteOfDay() {
  const quoteKey = "quote_of_day";
  const quoteTranslatedKey = "quote_of_day_selected";
  const selectedLanguageKey = "selectedLanguageCode";
  const storedQuote = await this.storage.get(quoteKey);
  const storedQuoteTrans = await this.storage.get(quoteTranslatedKey);
  const selectedLanguageCode = await this.storage.get(selectedLanguageKey);
  const today = new Date();
  const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  // Check if stored quote is from today
  if (storedQuote && storedQuote.date === todayString) {
    // Check if language has changed and translation is required
    if (selectedLanguageCode !== "en" && (!storedQuoteTrans || storedQuoteTrans.lang !== selectedLanguageCode)) {
      const translatedQuote = await this.translateQuote(storedQuote.quote.content, "en", selectedLanguageCode);
      const translatedQuoteData = { content: translatedQuote, author: storedQuote.quote.author };
      // Store translated quote
      await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
      return [storedQuote.quote, translatedQuoteData];
    } else {
      // No translation needed, return stored quote
      return [storedQuote.quote, storedQuoteTrans ? storedQuoteTrans.quote : null];
    }
  } else {
    // Fetch and store a new quote from local file
    try {
      // console.log("Attempting to fetch quotes from local file...");

      // const response = await fetch('/assets/quotes.json');
      // if (!response.ok) {
      //   throw new Error(`Failed to load quotes: ${response.status} ${response.statusText}`);
      // }

      // const data = await response.json(); // Properly parse JSON here
      // console.log("Quotes loaded successfully:", data);

      // const quotes = data.quotes; // Access the quotes array

      // Select a random quote
      const randomQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
      const quoteData = { content: randomQuote.quote, author: randomQuote.author };

      // Store new quote with today's date
      await this.storage.set(quoteKey, { quote: quoteData, date: todayString });

      if (selectedLanguageCode && selectedLanguageCode !== "en") {
        const translatedQuote = await this.translateQuote(quoteData.content, "en", selectedLanguageCode);
        const translatedQuoteData = { content: translatedQuote, author: quoteData.author };
        await this.storage.set(quoteTranslatedKey, { lang: selectedLanguageCode, quote: translatedQuoteData, date: todayString });
        return [quoteData, translatedQuoteData];
      } else {
        return [quoteData, null];
      }
    } catch (error) {
      console.error("Error fetching quote of the day from local file:", error);
      throw error;
    }
  }
}




//  function to translate the quote
async translateQuote(text, sourceL, targetL) {
  const hard_langs = ["ko", "hi", "zh", "id", "th", "vi", "ja"];
  let apiEndpoint;
  let requestBody;
  let headers = {};

  // if (hard_langs.includes(sourceL) || hard_langs.includes(targetL)) {
  //   apiEndpoint = "https://0tqla5kpmb.execute-api.ap-southeast-1.amazonaws.com/test/";
  //   requestBody = new URLSearchParams({ from: sourceL, to: targetL, text });
  // } else {
  //   apiEndpoint = "https://yq164ah9bl.execute-api.ap-southeast-1.amazonaws.com/test/";
  //   requestBody = JSON.stringify({ q: text, source: sourceL, target: targetL, format: "text" });
  //   headers = { "Content-Type": "application/json" };
  // }

  //always use hard langs api, instead of using custom server for easy languages
  //TODO: cache data in the future so not hitting the server with the same data repeatedly

  // if (hard_langs.includes(sourceL) || hard_langs.includes(targetL)) {
  //   apiEndpoint = "https://0tqla5kpmb.execute-api.ap-southeast-1.amazonaws.com/test/";
  //   requestBody = new URLSearchParams({ from: sourceL, to: targetL, text });
  // } else {
  //   apiEndpoint = "https://yq164ah9bl.execute-api.ap-southeast-1.amazonaws.com/test/";
  //   requestBody = JSON.stringify({ q: text, source: sourceL, target: targetL, format: "text" });
  //   headers = { "Content-Type": "application/json" };
  // }

  //Always use API for now
    apiEndpoint = "https://0tqla5kpmb.execute-api.ap-southeast-1.amazonaws.com/test/";
    requestBody = new URLSearchParams({ from: sourceL, to: targetL, text });




  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: requestBody,
      headers: headers
    });

    const data = await response.json();
    return data.trans || data.translatedText || text;
  } catch (error) {
    console.error('Error translating quote:', error);
    return text; // Return original text in case of an error
  }
}




  //this function ensures there is a year structure to write to
  async initializeYearData(year) {
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey);
  
    //console.log("yearData", yearKey, yearData)
    //if no year data, then create it for the entire year
    if (!yearData) {
      let newYearData = {};
      for (let month = 0; month < 12; month++) {
        // Get the number of days in the month
        let daysInMonth = new Date(year, month + 1, 0).getDate();
        for (let day = 1; day <= daysInMonth; day++) {
          let date = new Date(year, month, day);
          const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
          newYearData[dateString] = 0;
        }
      }
      await this.storage.set(yearKey, newYearData);
    }
  }
  
  

  //Returns current year and prev year data as an object with the year being the key, If prev year is not available it will just return an empty object for that year
  async getYearData() {
    const currentYear = new Date().getFullYear();
    const prevYear = currentYear - 1;
    const currentYearData = await this.storage.get(`views_${currentYear}`) || {};
    const prevYearData = await this.storage.get(`views_${prevYear}`) || {};

    return {
      [currentYear]: currentYearData,
      [prevYear]: prevYearData
    };
  }


  // Increment the current day's view count by +1
  // async incrementTodayView() {
  //   const today = new Date().toISOString().split('T')[0];
  //   const year = new Date().getFullYear();
  //   const yearKey = `views_${year}`;
  //   const yearData = await this.storage.get(yearKey) || {};
  //   console.log("incrementTodayView data:", today,  yearData)
  //   yearData[today] = (yearData[today] || 0) + 1;
  //   console.log("today's count is: ", yearData[today]);
  //   await this.storage.set(yearKey, yearData);
  // }
  async incrementTodayView() {
    const today = new Date();
    const localDateString = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey) || {};
  
    console.log("incrementTodayView data:", localDateString,  yearData);
    yearData[localDateString] = (yearData[localDateString] || 0) + 1;
    console.log("today's count is: ", yearData[localDateString]);
  
    await this.storage.set(yearKey, yearData);
  }
  

  // Set the specified year and date to a value provided by the caller
  async setViewCount(year, date, count) {
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey);

    if (yearData && yearData.hasOwnProperty(date)) {
      yearData[date] = count;
      await this.storage.set(yearKey, yearData);
    } else {
      throw new Error('Date not found in the specified year');
    }
  }


  // Resets the counter to zero for a given year and day, if year does not exist an error is returned
  async resetViewCount(year, date) {
    const yearKey = `views_${year}`;
    const yearData = await this.storage.get(yearKey);

    if (yearData && yearData.hasOwnProperty(date)) {
      yearData[date] = 0;
      await this.storage.set(yearKey, yearData);
    } else {
      throw new Error('Date not found in the specified year');
    }
  }


  // Delete the key for a sepcific year, if year '0000' is provided then delete all years
  async deleteYearData(year) {
    if (year === '0000') {
      // Delete all years
      const keys = await this.storage.keys();
      const viewKeys = keys.filter(key => key.startsWith('views_'));
      await Promise.all(viewKeys.map(key => this.storage.remove(key)));
    } else {
      await this.storage.remove(`views_${year}`);
    }
  }


}

const userActivityService = new UserActivityService()
export default userActivityService
