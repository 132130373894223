import {
  IonLabel,
  IonRouterOutlet,
  IonSplitPane,
  IonTabButton,
  IonTabs,
  IonTabBar,
  IonIcon,
} from "@ionic/react"
import React, { useState, useEffect } from "react"
import { Redirect, Route, useLocation, useHistory } from "react-router-dom"
import Menu from "./Menu/Menu"
import Zplayer from "../pages/Zplayer"
import HomePage from "../pages/HomePage"
import VideoPlayerPage from "../pages/VideoPlayerPage"
import SrtPlayer from "../pages/SrtPlayer"
import ChannelDetail from "../pages/ChannelDetail"
import FavoritesPage from "../pages/FavoritesPage"
import HistoryPage from "../pages/HistoryPage"
import NotesPage from "../pages/NotesPage"
import ExploreContainer from "../components/ExploreContainer/ExploreContainer"
import SplashScreen from "../components/SplashScreen/SplashScreen"
import ResetPasswordPage from "../pages/ResetPasswordPage"
import OnboardingPage from "../pages/OnboardingPage"
import DashboardPage from "../pages/DashboardPage"
import ZLplayer from "../pages/ZLplayer"
import TranscribePage from "../whisper/TranscribePage"
import FlashCardPage from "../pages/FlashCardPage"

import {
  homeOutline,
  albumsOutline,
  heartOutline,
  documentOutline,
  newspaperOutline,
  searchOutline,
  layersOutline,
} from "ionicons/icons"
import LoginPage from "../pages/LoginPage"
import SignupPage from "../pages/SignupPage"
import { UserAuth } from "../auth"
import { Storage } from "@ionic/storage"
import { useSearch } from "../SearchContext"

import AdminPage from "../pages/AdminPage"
import WordsPage from "../pages/WordsPage"
import TodoPage from "../pages/TodoPage"

const storage = new Storage()
storage.create()

const Zmain: React.FC = () => {
  const { user } = UserAuth()
  const hideTabBarRoutes = ["/video/:id", "myvideo"]
  const location = useLocation()
  const shouldHideTabBar = hideTabBarRoutes.some((path) =>
    location.pathname.includes(path.split(":")[0])
  )
  console.log("Current Route:", location.pathname)
  const history = useHistory()

  const { updateSearchResultsCh, updateLastChannel } = useSearch()
  const routesToCheck = ["/channel", "/video"]

  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    // Check if the splash screen has been shown in this session
    const splashShown = sessionStorage.getItem("splashShown")

    if (!splashShown) {
      setShowSplash(true)
      sessionStorage.setItem("splashShown", "true")
    }
  }, [])

  //Checks user authentication
  useEffect(() => {
    if (
      !user &&
      location.pathname !== "/resetpw" &&
      location.pathname !== "/signup"
    ) {
      history.replace("/login") // Redirect to login page if not authenticated and not on reset password page
    }

    //clear cached channel info if current route not in list routesToCheck
    const routeMatches = routesToCheck.some((route) =>
      location.pathname.startsWith(route)
    )
    if (!routeMatches) {
      updateSearchResultsCh([])
      updateLastChannel({})
      //last clicked video context (lastClickedIndex) will also be cleared by SearchBar
    }
    if (user) {
      console.log("AUTH DATA: ", user)
    }
  }, [user, history, location.pathname])

  // Function to determine if a tab should be selected based on the current path
  const isSelectedTab = (path: string) => {
    return location.pathname.startsWith(path)
  }

  const handleSplashEnd = () => {
    console.log("Splash screen handleSplashEnd triggered")
    setShowSplash(false)
  }

  //check if an object is empty
  const isObjectEmpty = (obj) => {
    console.log("Zmain Examining obj: ", obj)
    if (obj == null || typeof obj !== "object") {
      return true
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  // show only the splash screen and prevent any premature routes as the app initializes
  if (showSplash) {
    //remove any search terms that were used in the previous session
    storage.remove("lastSearchTerm")
    // If splash screen is visible, render only the SplashScreen component
    return (
      <SplashScreen onEnd={() => setShowSplash(false)} isVisible={showSplash} />
    )
  }

  return (
    <IonSplitPane contentId="main" when="(min-width: 2992px)">
      <Menu />

      <SplashScreen onEnd={() => setShowSplash(false)} isVisible={showSplash} />

      <IonRouterOutlet id="main">
        <Route path="/login" exact={true} component={LoginPage}></Route>
        <Route path="/signup" exact={true} component={SignupPage}></Route>
        <Route
          path="/resetpw"
          exact={true}
          component={ResetPasswordPage}
        ></Route>

        <IonTabs>
          <IonRouterOutlet id="tabs" key={location.pathname}>
            <Route path="/" exact={true}>
              <Redirect to="/home/new" />
            </Route>

            <Route path="/home" exact={true}>
              <Redirect to="/home/new" />
            </Route>
            {!isObjectEmpty(user) ? (
              <Route path="/video/:id" component={Zplayer} />
            ) : (
              <h1>No AUTH YET, WAIT.</h1>
            )}

            <Route path="/channel/:id">
              <ChannelDetail />
            </Route>

            <Route path="/search/:searchTerm" exact={true}>
              <HomePage />
            </Route>

            <Route path="/home/:searchTerm">
              <HomePage />
            </Route>

            <Route path="/player">
              <VideoPlayerPage />
            </Route>

            <Route path="/zplayer/:id">
              <Zplayer />
            </Route>

            <Route path="/myvideo">
              <ZLplayer />
            </Route>

            <Route path="/srtplayer">
              <SrtPlayer />
            </Route>

            <Route path="/dash">
              <DashboardPage />
            </Route>

            <Route path="/favorites">
              <FavoritesPage />
            </Route>

            <Route path="/myhistory">
              <HistoryPage />
            </Route>

            <Route path="/mynotes">
              <NotesPage />
            </Route>

            <Route path="/myprocess">
              <TranscribePage />
            </Route>

            <Route path="/adminpage">
              <AdminPage />
            </Route>

            <Route path="/wordspage">
              <WordsPage />
            </Route>

            <Route path="/todopage">
              <TodoPage />
            </Route>

            <Route path="/vocab">
              <FlashCardPage />
            </Route>


            <Route path="/explainer">
              <ExploreContainer
                videos={[
                  "/explainer-generatesubs.mp4",
                  "/explainer-translateword.mp4",
                  "/explainer-loop.mp4",
                  "/explainer-speedoverlay.mp4",
                  "/explainer-bookmarkfav.mp4",
                ]}
              />
            </Route>

            <Route path="/welcome">
              <OnboardingPage />
            </Route>

            <Route
              path="/comingsoon"
              exact
              render={() => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    fontSize: "3rem",
                    fontWeight: "bold",
                    color: "lightgrey",
                    textShadow: "2px 2px 2px black",
                  }}
                >
                  Coming Soon
                </div>
              )}
            />
          </IonRouterOutlet>

          <IonTabBar
            slot="bottom"
            className={shouldHideTabBar ? "hide-tab-bar" : ""}
          >
            <IonTabButton
              tab="dashboard"
              href="/dash"
              selected={isSelectedTab("/dash")}
            >
              <IonIcon icon={homeOutline} />
              <IonLabel>Dashboard</IonLabel>
            </IonTabButton>

            <IonTabButton
              tab="home"
              href="/home/new"
              selected={isSelectedTab("/home/new")}
            >
              <IonIcon icon={searchOutline} />
              <IonLabel>Search</IonLabel>
            </IonTabButton>

            <IonTabButton
              tab="favorites"
              href="/favorites"
              selected={isSelectedTab("/favorites")}
            >
              <IonIcon icon={heartOutline} />
              <IonLabel>Favorites</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

export default Zmain
