import React, { useState, useEffect } from "react"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonButton,
  IonAlert,
  IonMenuButton,
  IonButtons,
  IonListHeader,
  IonLabel,
} from "@ionic/react"
import wordsService from "../utils/WordsService"
import { useHistory } from "react-router-dom"
import VideoCardCompactWord from "../components/VideoCard/VideoCardCompactWord"
import { AnimatePresence, motion } from 'framer-motion';

interface WordsVideo {
  id: string
  title: string
  channelTitle: string
  description: string
  thumbnail: string
  channelId: string
  sourceLanguage: string
  sourceText: string
  sourceTileText: string
  sourceTileIndex: number
  targetLanguage: string
  targetText: string
  targetTileText: string
  addedDate: string
  reviewCount: number
  reviewDates: string[]
  notes: string
  quizResult?: boolean;
}

const WordsPage: React.FC = () => {
  const [history, setHistory] = useState<WordsVideo[]>([])
  const [searchTerm, setSearchTerm] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const historyRouter = useHistory()
  const [deckWords, setDeckWords] = useState<string[]>([])
  

  useEffect(() => {
    ;(async () => {
      const hist = await wordsService.getWords()
      console.log("RETRIEVED from storage: ", hist)
      setHistory(hist)

      // Fetch the srvocab deck and set deckWords
      const deck = await wordsService.getDeckWords()
      setDeckWords(deck)

    })()
  }, [])

  useEffect(() => {
    return historyRouter.listen(() => {
      ;(async () => {
        const hist = await wordsService.getWords()
        setHistory(hist)
      })()
    })
  }, [historyRouter])

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const filteredHistory = history.filter((video: any) =>
    video.sourceText.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const adjustedHistory = filteredHistory.map((video) => ({
    id: { videoId: video.id },
    channelId: video.channelId,
    snippet: {
      title: video.title,
      channelTitle: video.channelTitle,
      description: video.description,
      thumbnails: {
        medium: {
          url: video.thumbnail,
        },
      },
      channelId: video.channelId,
    },
    sourceLanguage: video.sourceLanguage,
    sourceText: video.sourceText,
    sourceTileText: video.sourceTileText,
    sourceTileIndex: video.sourceTileIndex,
    targetLanguage: video.targetLanguage,
    targetText: video.targetText,
    targetTileText: video.targetTileText,
  }))

  const handleClearHistory = async () => {
    setShowAlert(false)
    await wordsService.clearWords()
    const hist = await wordsService.getWords()
    setHistory(hist)
  }
  
  //update deckWords when a word is added
  const updateDeckWords = (newWord: string) => {
    setDeckWords((prevDeckWords) => [...prevDeckWords, newWord])
  }

  const handleWordUpdated = () => {
    // Refresh the data
    console.log("called WordsPage->updateDeckWords, which doesn't need it since read only")
  }



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
              <IonMenuButton color="primary" />
          </IonButtons>
          <IonTitle>Recent Words [{adjustedHistory.length}]</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="compact-list-padding">

        <IonSearchbar
          className="custom-searchbar-local"
          value={searchTerm}
          onIonChange={handleSearch}
          debounce={500}
          enterkeyhint="search"
          placeholder="Search words"
        ></IonSearchbar>
        <IonButton
          className="custom-button-main"
          expand="block"
          onClick={() => setShowAlert(true)}
        >
          Clear List
        </IonButton>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={"Confirmation"}
          message={"Your recent words will be cleared, proceed?"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                setShowAlert(false)
              },
            },
            {
              text: "Yes",
              handler: handleClearHistory,
            },
          ]}
        />
        {/*adjustedHistory.map((video) => (
          <VideoCardCompactWord video={video} key={`${video.sourceText}`} />
        )) */}
        <AnimatePresence>
        {adjustedHistory.map((video) => (
          <motion.div
          key={video.sourceText}
          layout
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 0.5 }}
        >
          <VideoCardCompactWord
            video={video}
            key={`${video.sourceText}`}
            deckWords={deckWords} // Pass down the deck words
            updateDeckWords={updateDeckWords} // Pass down the function to update deck words
            onWordUpdated={handleWordUpdated}
            allowEditing={false}
            quizResult={undefined}
          />
          </motion.div>
        ))}
        </AnimatePresence>
      </IonContent>
    </IonPage>
  )
}

export default WordsPage
