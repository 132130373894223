/*
Component that displays a list of videos from a search query to Youtube.
Do note the list of a particular channel's video has it's own component, although
it maybe similar a channel has more than just videos that may be implemented in the future.

*/
import { useState, useEffect } from "react"
import { IonLoading, IonButton, IonText, IonToast } from '@ionic/react';
import Videos from "./VideoCard/Videos"
import { apiYTALT } from "../utils/apiYT"
import { useSearch } from '../SearchContext';
import { useLocation } from "react-router-dom";
import { UserAuth } from "../auth"

interface Thumbnail {
  url: string;
  width: number;
  height: number;
}

interface Video {
  type: string;
  videoId: string;
  title: string;
  channelTitle: string;
  channelId: string;
  channelHandle: string;
  channelThumbnail: Thumbnail[];
  description: string;
  viewCount: string;
  publishedTimeText: string;
  publishDate: string;
  publishedAt: string;
  lengthText: string;
  badges: string[];
  thumbnail: Thumbnail[];
  richThumbnail: Thumbnail | null; // Assuming richThumbnail has the same structure as thumbnail, or null if not present
}


const SearchResults = ({ searchTerm }) => {
  //const { searchTerm } = useParams<{ searchTerm: string; }>();
  const [videos, setVideos] = useState<Video[]>([]); //useState([])
  const [pagination, setPagination] = useState('')
  const [showLoading, setShowLoading] = useState(false);
  const [requestTimedOut, setRequestTimedOut] = useState(false);
  const { searchResults, updateSearchResults , updateLastClickedIndex,searchResultsHome, updateSearchResultsHome,lastHomeCheck, updateLastHomeCheck} = useSearch(); //search CONTEXT
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');  
  const location = useLocation();
  const { nativeLanguageCode,selectedLanguageCode } = UserAuth()

  const hasTwelveHoursPassed = (lastTimestamp: number | null): boolean => {
    if (lastTimestamp === null) {
      return true; // If no timestamp is set, assume 12 hours have passed
    }
  
    const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
    const currentTime = Date.now();
    
    return (currentTime - lastTimestamp) >= twelveHoursInMilliseconds;
  };
  

  useEffect(() => {
      // Check if the current route includes "/home"
      const isHomeRoute = location.pathname.includes("/home");
    // If searchResults is already populated, use it
    console.log("The Value of searchResults is.... :", searchResults)
    if (!isHomeRoute && searchResults && searchResults.length > 0) {
      setVideos(searchResults);
      return;
    }

    //check if cached, if any, home results data can be used
    if (isHomeRoute && !hasTwelveHoursPassed(lastHomeCheck) && searchResults && searchResultsHome.length > 0) {
      setVideos(searchResultsHome);
      return;
    }
    

    //set 5sec timer for IonLoading indicator
    let timeoutId = setTimeout(() => {
      setShowLoading(false);
      setRequestTimedOut(true);
    }, 8000); // 8 seconds timeout

    setShowLoading(true);
    setRequestTimedOut(false);
    console.log("SearchResults call api with term: "+ searchTerm);

    //apiYT(`search?part=snippet&q=${searchTerm}`).then((data: any) => setVideos(data.items))
    //TODO: allow user to set the geo and lang properties in the future or based on their region
    const searchObj = {
      query: searchTerm,
      geo: "US",
      lang: nativeLanguageCode || 'en',
      targetLang: selectedLanguageCode || 'en'
    }
    apiYTALT(searchObj).then((data: any) => {


      if (isHomeRoute && searchTerm == 'new') {
        // setVideos(data.data); // Set the videos list
        setVideos(data); // custom home videos list, no longer nested under data obj
        // updateSearchResults(data.data); // Update the context with new results
        updateSearchResults(data); // Update the context with new results
        updateLastHomeCheck(Date.now());
        // updateSearchResultsHome(data.data);
        updateSearchResultsHome(data);
        console.log("Updated searchResults with: ", data);
      } else {
        // setVideos(data.data); // Set the videos list
        setVideos(data.data); // custom home videos list, no longer nested under data obj
        // updateSearchResults(data.data); // Update the context with new results
        updateSearchResults(data.data); // Update the context with new results

        //only set for normal search, not home page results
        setPagination(data.continuation); // Set pagination data
        console.log("Set Pagination data: ", data.continuation);
      }
      
      
      //console.log("NEW Pagination token set: "+ data.continuation)
      setShowLoading(false);

      clearTimeout(timeoutId); //clear IonLoading timeer
    }).catch((error) => {
      setToastMessage("Server error loading videos.");
      clearTimeout(timeoutId); //clear IonLoading timeer
      console.error("Catch: API call failed:", error);
      setShowLoading(false);
      setRequestTimedOut(true);
      setShowToast(true);
    });
    return () => clearTimeout(timeoutId); // Clear timeout on unmount
  }, [searchTerm, location.pathname])


  //when the user clicks on "More" button to load additional videos
  const handlePagination = () => {
    setShowLoading(true);
    const isHomeRoute = location.pathname.includes("/home");
    const searchObj = {
      query: searchTerm,
      geo: "US",
      lang: "en",
      token: pagination, // pagination token for the next set of videos
    };
    //set 5sec timer for IonLoading indicator
    let timeoutId = setTimeout(() => {
      setShowLoading(false);
      setRequestTimedOut(true);
    }, 5000); // 10 seconds timeout
    apiYTALT(searchObj).then((data: any) => {
      console.log("handlePagination: ",data)
      setVideos(prevVideos => [...prevVideos, ...data.data]); // Append new videos to existing list
      updateSearchResults(prevVideos => [...prevVideos, ...data.data]); // Update the context with new results
      setPagination(data.continuation);
      updateLastClickedIndex(-1)
      if (isHomeRoute && searchTerm == 'new') {
        updateLastHomeCheck(Date.now());
        updateSearchResultsHome(prevVideos => [...prevVideos, ...data.data]);
      }
      setShowLoading(false);
      clearTimeout(timeoutId); //clear IonLoading timeer

    }).catch((error) => {
      clearTimeout(timeoutId); //clear IonLoading timeer
      setToastMessage("No more home page videos, please use search.");
      console.error("Catch: API call failed:", error);
      setShowLoading(false);
      setRequestTimedOut(true);
      setShowToast(true);
    });;
  };

 

  return (
    <> 
      <IonToast
      isOpen={showToast}
      onDidDismiss={() => setShowToast(false)}
      message={toastMessage}
      duration={3000}
      position="middle"
    />
      {/* videos.length > 0 && <Videos videos={videos} /> */}
      {videos && videos.length > 0 ? (
        <>
          <Videos videos={videos} />
          {pagination && (
            <IonButton expand="block" fill="outline" color="medium" onClick={handlePagination}>More</IonButton>
          )}
        </>
      ) : requestTimedOut ? (
        <IonText color="primary">
          No videos available at the moment.
        </IonText>
      ) : null}
      
      <IonLoading
        isOpen={showLoading}
        message={'Loading videos...'}
      />
    </>
  )
};

export default SearchResults
